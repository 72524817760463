import axios from 'axios';

// async function getLocation() {
//     if (navigator.geolocation) {
//         const aa = await navigator.geolocation.getCurrentPosition((position)=>{return position.coords}, showError);
//         console.log(aa)
//     } else { 
//       console.log('Geolocation is not supported by this browser.');
//     }
    
//   }
  

//   function showPosition(position) {
//     console.log({Latitude : position.coords.latitude, Longitude : position.coords.longitude})
//     const latitude = position.coords.latitude
//     const longitude = position.coords.longitude
//     return {latitude, longitude}
//   }
  
//   function showError(error) {
//     switch(error.code) {
//       case error.PERMISSION_DENIED:
//         return "User denied the request for Geolocation."
//         break;
//       case error.POSITION_UNAVAILABLE:
//         return "Location information is unavailable."
//         break;
//       case error.TIMEOUT:
//         return "The request to get user location timed out."
//         break;
//       case error.UNKNOWN_ERROR:
//         return "An unknown error occurred."
//         break;
//     }
//   }


// const token = sessionStorage.getItem('token');
const api = axios.create({
    //baseURL: `${process.env.HOST}:${process.env.PORT}`
    baseURL: process.env.REACT_APP_API_URL,
    // onUploadProgress: function (progressEvent) {
    //     // console.log('UploadProgress: '+ parseInt((progressEvent.loaded * 100) / progressEvent.total)+'%')
    //     return parseInt((progressEvent.loaded * 100) / progressEvent.total)+'%'
    //   },
    // onDownloadProgress: function (progressEvent) {
    //     // console.log('DownloadProgress: '+ parseInt((progressEvent.loaded * 100) / progressEvent.total)+'%')
    //     return parseInt((progressEvent.loaded * 100) / progressEvent.total)+'%'
    // },
    // getLocation: getLocation()
    // getLocation: {latitude: 'lat', longitude: 'long'}
    //baseURL: 'http://192.168.0.5:3333'
});

// const data = getLocation()
// console.log(data)

// api.defaults.headers.common['Authorization'] = `Bearer ${token}`



export default api;
