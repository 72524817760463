import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import api from '../../../services/api'

import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import InputText from '../../../components/form/primeReact/inputText'
import TextAreaPrime from '../../../components/form/primeReact/textArea'
import SelectPrime from '../../../components/form/primeReact/select'
import ButtonPrime from '../../../components/form/primeReact/button'

import { Container } from './styles'

const NovaOrdemServico : React.FC = ({ idChamado }) =>{
    const [loading, setLoading] = useState(false)

    const [displayBasic, setDisplayBasic] = useState(false);
    const [codigoValidacao, setCodigoValidacao] = useState('')
    const [codigoValidacaoCheck, setCodigoValidacaoCheck] = useState(false)

    const [users, setUsers] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);

    const [id, setId] = useState('')
    const [tituloOcorrencia, setTituloOcorrencia] = useState('')
    const [localOcorrencia, setLocalOcorrencia] = useState('');
    const [equipamento, setEquipamento] = useState('')
    const [situacaoEquipamento, setSituacaoEquipamento] = useState('')
    const [tipoManutencao, setTipoManutencao]= useState('')
    const [tipoServico, setTipoServico]= useState('')
    const [descricaoProblema, setDescricaoProblema] = useState('');

    const [prioridade, setPrioridade] = useState('');
    const [dtAgendamento, setDtAgendamento] = useState('');
    const [hrAgendamento, setHrAgendamento] = useState('');
    const [tecnico, setTecnico] = useState('');

    const [status, setStatus] = useState('')
    const [iniAtendimento, setIniAtendimento] = useState('')
    const [fimAtendimento, setFimAtendimento] = useState('')
    const [parecerTecnico, setParecerTecnico] = useState('')

    const dataSituacao = [{value: 'Erro Continuo', label: 'Erro Continuo'},{value: 'Parada', label: 'Parada'},{value: 'Operacional', label: 'Operacional'}]
    const dataTipoManut = [{value: 'Corretiva', label: 'Corretiva'},{value: 'Preventiva', label: 'Preventiva'},{value: 'Preditiva', label: 'Preditiva'},{value: 'Melhoria', label: 'Melhoria'},{value: 'Implantação', label: 'Implantação'},{value: 'Desinstalação', label: 'Desinstalação'},{value: 'Treinamento', label: 'Treinamento'}]
    const dataTipoServ = [{value: 'Elétrica / Eletrônica', label: 'Elétrica / Eletrônica'},{value: 'Civil / Predial', label: 'Civil / Predial'},{value: 'Mecânica', label: 'Mecânica'}]
    const dataPrioridade = [{value: 'Critica', label: 'Critica'}, {value: 'Muito Alta', label: 'Muito Alta'}, {value: 'Alta', label: 'Alta'}, {value: 'Média', label: 'Média'}, {value: 'Baixa', label: 'Baixa'}, {value: 'Muito Baixa', label: 'Muito Baixa'}]
    const dataStatus = [{value: 'Pendente', label: 'Pendente'},{value: 'Direcionado', label: 'Direcionado'},{value: 'Iniciado', label: 'Iniciado'},{value: 'Finalizado', label: 'Finalizado'}]

    const [edit, setEdit] = useState(true)

    const {perfil : perfilLogged, name} = JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        async function handle_getEquipamentos(){
            setLoading(true)
            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${localOcorrencia}/equipamento`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setEquipamentos(response.data.result.map(cliente => {
                        return {'value': cliente.uuid, 'label': `${cliente.nome.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        })} ${cliente.modelo ? ' - ' + cliente.modelo.toUpperCase() : ''}`}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        if (localOcorrencia)
            handle_getEquipamentos();
    }, [localOcorrencia])

    useEffect(() => {
        async function handle_getUsers(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get('/users', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setUsers(response.data.users.map(user => {
                            return  {'value': user.uuid, 'label': user.name.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                return a.toUpperCase();
                            }) + ' - ' + user.perfil}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        async function handle_getClientes(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get('/clientes', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setClientes(response.data.result.map(cliente => {
                        return {'value': cliente.uuid, 'label': cliente.fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        })}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        async function getChamado() {
            setLoading(true);

            const token = sessionStorage.getItem('token');
                await api.get(`/chamados/${idChamado}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setId(response?.data?.chamado?.id)
                    setLocalOcorrencia(response?.data?.chamado?.local_ocorrencia)
                    setTituloOcorrencia(response?.data?.chamado?.titulo_ocorrencia.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setEquipamento(response?.data?.chamado?.equipamento)
                    setSituacaoEquipamento(response?.data?.chamado?.situacao_equipamento)
                    setTipoManutencao(response?.data?.chamado?.tipo_manutencao)
                    setTipoServico(response?.data?.chamado?.tipo_servico)
                    setDescricaoProblema(response?.data?.chamado?.descricao_ocorrencia.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setTecnico(response?.data?.chamado?.uuid_tecnico)
                    setPrioridade(response?.data?.chamado?.prioridade)
                    setDtAgendamento(response?.data?.chamado?.data_agendamento ? response?.data?.chamado?.data_agendamento.slice(0, 10) : '')
                    setHrAgendamento(response?.data?.chamado?.hora_agendamento ? response?.data?.chamado?.hora_agendamento : '')

                    setStatus(response?.data?.chamado?.status.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setIniAtendimento(response?.data?.chamado?.inicio_atendimento ? `${new Date(response?.data?.chamado?.inicio_atendimento).toLocaleDateString('fr-CA')}T${new Date(response?.data?.chamado?.inicio_atendimento).toLocaleTimeString('en-GB')}` : '')
                    setFimAtendimento(response?.data?.chamado?.fim_atendimento ? `${new Date(response?.data?.chamado?.fim_atendimento).toLocaleDateString('fr-CA')}T${new Date(response?.data?.chamado?.fim_atendimento).toLocaleTimeString('en-GB')}` : '')
                    setParecerTecnico(response?.data?.chamado?.parecer_tecnico)
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        getChamado();
        handle_getUsers();
        handle_getClientes();
    }, [idChamado])

    function handleEditarChamado(event){
        event.preventDefault()
        setLoading(true)

        const token = sessionStorage.getItem('token');
        try {
            api.put(`/chamados/${idChamado}`,
            {
                titulo_ocorrencia: tituloOcorrencia,
                local_ocorrencia: localOcorrencia,
                equipamento,
                situacao_equipamento: situacaoEquipamento,
                tipo_manutencao: tipoManutencao,
                tipo_servico: tipoServico,
                descricao_ocorrencia: descricaoProblema,

                prioridade,
                data_agendamento: dtAgendamento ? dtAgendamento : undefined,
                hora_agendamento: hrAgendamento ? hrAgendamento : undefined,
                uuid_tecnico: tecnico
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 200)
                    toast.success('Ordem serviço atualizada');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
    }

    function handleLiberarEdicao(event){
        event.preventDefault()
        setEdit(false)
    }

    function handleIniciaRemoto(event){
        event.preventDefault()

        const parecer = '' //'finalizado remotamente pq operador ficou doente'
        
        const msg = ((`** Ordem serviço iniciada remotamente por  ( ${name} - ${perfilLogged} ) **\n     ${parecer}`).toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
            return a.toUpperCase();
        }));

        setLoading(true)

        const token = sessionStorage.getItem('token');
        try {
            api.put(`/chamados/${idChamado}`,
            {
                status: 'Iniciado',
                parecer_tecnico: parecerTecnico ? parecerTecnico + '\n' + msg : msg,
                inicio_atendimento_remoto : 'Remoto'
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 200)
                    toast.success('Ordem serviço atualizado');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
        
    }

    function handleFinalizaRemoto(event){
        event.preventDefault()

        const parecer = '' //'finalizado remotamente pq operador ficou doente'
        
        const msg = ((`** Ordem serviço finalizada remotamente por  ( ${name} - ${perfilLogged} ) **\n     ${parecer}`).toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
            return a.toUpperCase();
        }));

        setLoading(true)

        const token = sessionStorage.getItem('token');
        try {
            api.put(`/chamados/${idChamado}`,
            {
                status: 'Finalizado',
                parecer_tecnico: parecerTecnico ? parecerTecnico + '\n' + msg : msg,
                fim_atendimento_remoto : 'Remoto'
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 200)
                    toast.success('Ordem serviço atualizada');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
        
    }

    useEffect(()=>{
        function validaCodigo(){
            if (codigoValidacao === idChamado?.split("-")?.[0]) setCodigoValidacaoCheck(true)
        }

        validaCodigo()
    }, [codigoValidacao])
    const onHide = (name) => {
        setCodigoValidacaoCheck(false)
        setDisplayBasic(false)
    }    
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Não" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" autoFocus/>
                <Button label="Sim" icon="pi pi-check" onClick={handleDeleteOS} disabled={!codigoValidacaoCheck} />
            </div>
        );
    }    
    function handleDeleteOS(event){
        event.preventDefault()
        setLoading(true)
        setDisplayBasic(false)

        const token = sessionStorage.getItem('token');
        try {
            api.delete(`/chamados/${idChamado}`,
            {
                headers: {
                    authorization: `Bearer ${token}`
                },
            }).then(response => {
                // setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 200)
                    toast.success('Ordem Serviço excluída com sucesso');

            }).catch(error => {
                // setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              })
              .finally((response) =>{
                setLoading(false)
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
    }

    return <>
        <Loading loading={loading} message='Carregando...' />
        <Dialog header={`Excluir Ordem de Serviço ${id}?`} visible={displayBasic} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
            <p style={{lineHeight: '2.5rem', fontWeight: 'bold', borderLeft: '5px solid red', paddingLeft: '.5rem'}}>Atenção: Esta operação não poderá ser revertida</p>
            <br/>
            <p style={{marginBottom:'.2rem'}}>Confirme o código para continuar: <b style={{fontSize: '1.2rem'}}>{idChamado?.split("-")?.[0]}</b></p>
                <InputText
                    name={"codigoConfirmacao"}
                    placeholder={idChamado?.split("-")?.[0]}
                    type={"text"}
                    value={codigoValidacao}
                    setData={event => setCodigoValidacao(event.target.value)}
                />
        </Dialog>

        <Title title={"Editar Ordem Serviço"} subTitle={id} />
        <Card width="100%">
            <Container>                
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                <InputText
                        title={"Título Ocorrência"}
                        name={"tituloOcorrencia"}
                        type={"text"}
                        value={tituloOcorrencia}
                        setData={event => setTituloOcorrencia(event.target.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                        focus={true}
                    />

                </div>

                {/* <div className="p-field p-col-3 p-md-2">
                    <InputText
                        title={'ID O.S.'} 
                        name="idChamado" 
                        value={idChamado}
                        disabled={true}
                    />
                </div>                 */}
                <div className="p-field p-col-9 p-md-6">
                <SelectPrime 
                        title={'Cliente Ocorrência'} 
                        name={'tituloOcorrencia'} 
                        data={clientes} 
                        value={localOcorrencia}
                        setData={event => setLocalOcorrencia(event.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div>       
                <div className="p-field p-col-12 p-sm-6 p-md-6">
                    <SelectPrime 
                        title={"Equipamento"}
                        name={"equipamentos"}
                        data={equipamentos} 
                        value={equipamento}
                        setData={event => setEquipamento(event.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div>                           

                <div className="p-field p-col-12 p-sm-6 p-md-4">
                    <SelectPrime 
                        title={"Situação Equipamento"}
                        name={"situacaoEquipamento"}
                        data={dataSituacao} 
                        value={situacaoEquipamento}
                        setData={event => setSituacaoEquipamento(event.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-4">
                    <SelectPrime 
                        title={"Tipo Manutenção"}
                        name={"tipoManutencao"}
                        data={dataTipoManut} 
                        value={tipoManutencao}
                        setData={event => setTipoManutencao(event.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-4">
                    <SelectPrime 
                        title={"Tipo Serviço"}
                        name={"tipoServico"}
                        data={dataTipoServ} 
                        value={tipoServico}
                        setData={event => setTipoServico(event.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div> 

                <div className="p-field p-col-12">
                    <TextAreaPrime
                        title={"Descrição Problema"} 
                        name={'descricaoProblema'} 
                        rows={4} 
                        value={descricaoProblema}
                        setData={event => setDescricaoProblema(event.target.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />  
                </div> 

                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <SelectPrime 
                        title={"Técnico"}
                        name={"tecnico"}
                        data={users.filter(user => (user.label?.toLowerCase().split(' - ')[0] !== 'suporte'))} 
                        value={tecnico}
                        setData={event => setTecnico(event.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <SelectPrime 
                        title={"Prioridade"}
                        name={"urgencia"}
                        data={dataPrioridade} 
                        value={prioridade}
                        setData={event => setPrioridade(event.value)}
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <InputText
                        title="Data Agendamento"
                        type="date"
                        value= {dtAgendamento}
                        setData= {event => setDtAgendamento(event.target.value)} 
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                        />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <InputText
                        title="Hora Agendamento"
                        type="time" 
                        value= {hrAgendamento}
                        setData= {event => setHrAgendamento(event.target.value)} 
                        disabled={status === 'Iniciado' || status === 'Finalizado' ? true : perfilLogged === "Operador" ? true : edit}
                    />
                </div> 

                <div className="p-field p-col-12 p-sm-4">
                    <SelectPrime 
                        title={'Status'} 
                        name={'status'} 
                        data={dataStatus} 
                        value={status}
                        setData={event => setStatus(event.label)}
                        disabled={true}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-4 btn50">
                    <InputText
                        title="Inicio Atendimento"
                        type="datetime-local" 
                        value= {iniAtendimento}
                        disabled={true}
                    />
                    {
                        !edit && perfilLogged === 'Administrador' ?
                            <Button icon="pi pi-play" className={`p-button-rounded p-button-${iniAtendimento ? 'secondary' : 'success'}`} onClick={handleIniciaRemoto} />
                        :
                            ''
                    }
                </div> 
                <div className="p-field p-col-12 p-sm-4 btn50">
                    <InputText
                        title="Fim Atendimento"
                        type="datetime-local" 
                        value= {fimAtendimento}
                        disabled={true}
                    />
                    {
                        !edit && perfilLogged === 'Administrador' ?
                            <Button icon="pi pi-play" className={`p-button-rounded p-button-${iniAtendimento ? 'secondary' : 'success'}`} onClick={handleFinalizaRemoto} />
                        :
                            ''
                    }
                </div> 

                <div className="p-field p-col-12">
                    <TextAreaPrime
                        title={"Parecer Técnico"} 
                        name={'parecerTecnico'} 
                        rows={4} 
                        value={parecerTecnico}
                        disabled={true}
                    />  
                </div>                

            </div>
              
            <div style={{display:'flex',justifyContent:'space-evenly', width: '100%'}}>

            {
                perfilLogged === "Operador" ? '' :
                !edit ?
                // <button className="btn btn-primary mt-2" onClick={handleEditarUsuario}><MdSave fontSize={25}/> Salvar</button>
                <>
                        <ButtonPrime onClick={handleEditarChamado} text="Salvar" icon="save"/>
                        <ButtonPrime onClick={() => setDisplayBasic(true)} text="Excluir" icon="trash" colorBg="bluegray"/>
                    </>
                :
                // <button className="btn btn-outline-success mt-2" onClick={handleLiberarEdicao}><MdEdit fontSize={25}/> Editar</button>
                <ButtonPrime onClick={handleLiberarEdicao} text="Editar" icon="lock-open"/>
            }
            </div>
            
            </Container>
        </Card>
            </>
}

export default NovaOrdemServico;