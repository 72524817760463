import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import InputText from '../../../components/form/primeReact/inputText'
import InputPassword from '../../../components/form/primeReact/inputPassword'
import SelectPrime from '../../../components/form/primeReact/select'
import ButtonPrime from '../../../components/form/primeReact/button'

import { Container } from './styles'

const EditarUser : React.FC = ({ uuid }) =>{
    const [loading, setLoading] = useState(false)
    
    const [users , setUsers] = useState([]);
    
    const [id, setId] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [perfil, setPerfil] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [status, setStatus] = useState('');
    const [senha, setSenha] = useState('');
    
    const [edit, setEdit] = useState(true)

    // const  uuid = uuid; //useParams();

    const dataPerfil = [{value: 'Operador', label: 'Operador'},{value: 'Supervisor', label: 'Supervisor'},{value: 'Administrador', label: 'Administrador'}]    
    const dataStatus = [{value: 'Ativo', label: 'Ativo'},{value: 'Inativo', label: 'Inativo'}]

    const {perfil : perfilLogged} = JSON.parse(localStorage.getItem('user') || '');
    const {email : emailLogged} = JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        async function handle_getUser(){
            setLoading(true)

                const token = sessionStorage.getItem('token');
                await api.get(`/users/${uuid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setId(response.data?.user?.id)
                    setName(response.data?.user?.name.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }));
                    setEmail(response.data?.user?.email.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }));
                    setPerfil(response.data?.user?.perfil);
                    setSupervisor(response.data?.user?.supervisor)
                    setStatus(response.data?.user?.status);
                    // setSenha(response.data?.user?.password);
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                }).finally(() => {setLoading(false)});
        }

        async function handle_getUsers(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get('/users', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setUsers(response.data.users
                        .filter(user => user.perfil === 'Supervisor' && user.status==="Ativo")
                        .map(user => {
                        return {'value': user.uuid, 'label': user.name.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        }) + ' - ' + user.perfil}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                }).finally(() => {setLoading(false)});
        }

        handle_getUser()
        handle_getUsers()
    }, [uuid])

    function handleEditarUsuario(event) {
        event.preventDefault()
        setLoading(true)
        const token = sessionStorage.getItem('token');
        console.log(uuid)
        try {
            api.put(`/users/${uuid}`,
            {
                name,
                email,
                perfil,
                supervisor,
                status,
                password: senha
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
                if (response.data.code === 200)
                    toast.success('Usuário atualizado com sucesso');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }

      }

      useEffect(()=>{
        function handle_reseta_sup(){
            if(perfil !== "Operador") setSupervisor('')
      }

      handle_reseta_sup()
    },[perfil])      

      function handleLiberarEdicao(event){
        event.preventDefault()
        setEdit(false)
      }
    //   function handleEditarSenhaTexto(){
    //     setEditSenhaTexto(editSenhaTexto === 'text' ? 'password' : 'text')
    //   }

    return <>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Editar Cadastro de Usuário"}>
            {/* <NavLink to="/users/novo" className="btn btn-primary mb-2"><MdPersonAdd fontSize="24px" /> Novo Usuário</NavLink> */}
        </Title>
        <Card width="100%">
            <Container>                
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-2">
                    <InputText
                        title={'ID'} 
                        name="id" 
                        value={id}
                        disabled={true}
                    />
                </div>
                <div className="p-field p-col-10">
                    <InputText
                        title={'Nome'} 
                        name="name" 
                        value={name}
                        setData={event => setName(event.target.value)}
                        disabled={name === "Administrador" || name === "Suporte" || perfilLogged === "Operador" ? true : edit}
                        focus={true}
                    />
                </div>
                <div className="p-field p-col-12">
                    <InputText
                        title={'E-mail'} 
                        name="email" 
                        value={email}
                        setData={event => setEmail(event.target.value)}
                        disabled={name === "Administrador" || name === "Suporte" || perfilLogged === "Operador" ? true : edit}
                    />
                </div>

                <div className="p-field p-col-6">
                    <SelectPrime 
                        title={'Perfil'} 
                        name={'perfil'} 
                        data={perfilLogged === "Administrador" ? dataPerfil : dataPerfil.filter(data => data.value !== 'Administrador')}
                        value={perfil}
                        setData={event => setPerfil(event.value)}
                        disabled={name === "Administrador" || name === "Suporte" || perfilLogged !== "Administrador" ? true : edit}
                    />
                </div>

                <div className="p-field p-col-6">
                    <SelectPrime 
                        title={'Supervisor'} 
                        name={'supervisor'} 
                        data={users}
                        value={supervisor}
                        disabled={name === "Administrador" || name === "Suporte" || perfilLogged !== "Administrador" || perfil!=="Operador"? true : edit}
                        setData={event => setSupervisor(event.value)}
                    />
                </div>

                <div className="p-field p-col-12">
                    <InputPassword
                        title={'Senha'} 
                        name="password"
                        value={senha}
                        setData={event => setSenha(event.target.value)}
                        disabled={name === "Administrador" || emailLogged === "Suporte@executapp.com"? edit : name === 'Suporte'? true : edit}
                    />
                </div>

                <div className="p-field p-col-12">
                    <SelectPrime 
                        title={'Status'} 
                        name={'status'} 
                        data={dataStatus}
                        value={status}
                        setData={event => setStatus(event.value)}
                        disabled={name === "Administrador" || name === "Suporte" || perfilLogged === "Operador" ? true : edit}
                    /> 
                </div>
            </div>

            {
                !edit ?
                    // <button className="btn btn-primary mt-2" onClick={handleEditarUsuario}><MdSave fontSize={25}/> Salvar</button>
                    <ButtonPrime onClick={handleEditarUsuario} text="Salvar" icon="save"/>
                :
                    // <button className="btn btn-outline-success mt-2" onClick={handleLiberarEdicao}><MdEdit fontSize={25}/> Editar</button>
                    <ButtonPrime onClick={handleLiberarEdicao} text="Editar" icon="lock-open"/>
            }
            
            </Container>
        </Card>
            </>
}

export default EditarUser;