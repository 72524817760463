import React from 'react';
// import { NavLink } from 'react-router-dom';

import { Container, Name } from './styles';

const Title: React.FC = ({title, subTitle, children}) => {    

    return (
        <>
        <Container>
            <Name>
                <h3>{title}</h3>
                    {subTitle?
                        <>
                            <h6>#</h6>
                            <span>{subTitle}</span>
                        </>
                    :
                        ''
                    }
            </Name>
            {children}
        </Container>
        </>
    );
}

export default Title;