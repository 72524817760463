import React from 'react';
import { FiAlertCircle } from 'react-icons/fi'

import { Container, ContainerSelect, SelectReact, Headerselect, Title, Error } from './styles';

const Select : React.FC = ({
    name, title, register, errors, data, setData, value, disabled, defaultValue, focus, filter
}) => {
    // console.log(data.length)
      return (
        <>  
            <Container>
                <Headerselect>
                    {title && <Title>{title}:</Title>}
                </Headerselect>
                <ContainerSelect>
                <SelectReact  
                    value={value} 
                    options={data} 
                    onChange={setData} 
                    placeholder="Escolha..." 
                    emptyMessage="Sem Registos" 
                    emptyFilterMessage="Sem Registos" 
                    filter={filter? filter : data.length >= 10? true : filter}
                    showClear 
                    disabled={disabled}
                    autoFocus={focus}
                    // className="p-invalid"
                />
                    {/* <SelectReact 
                        styles={customStyles} 
                        maxMenuHeight={92} 
                        defaultValue={{value: 0, label: 'Escolha...'}} 
                        options={data} 
                        noOptionsMessage={() => "Não encontrado"}  
                        onChange={setData}
                        value = {value}
                        isDisabled = {isDisabled}
                        autoFocus={autoFocus}
                    /> */}
                    {/* <select className={'form-control'} disabled={disabled} value={value} name={name} ref={register} onChange={setData}>
                        <option value="" disabled hidden>--SELECIONE</option>

                        {
                            data.map(option => (<option key={option.id}>{option.name}</option>))
                        }
                    </select> */}
                    {errors && <FiAlertCircle size={20} />}
                </ContainerSelect>
                    <Error>{errors?.message}</Error>
            </Container>
        </>
    )
}

export default Select