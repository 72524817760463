import React from 'react';

import Routes from './routes';

import GlobalStyles from './styles/GlobalStyles';
import ToastContainer from './components/toastify'

import 'react-table-v6/react-table.css'
import 'react-toastify/dist/ReactToastify.css';

import 'primeflex/primeflex.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import PrimeReact from 'primereact/api';

PrimeReact.ripple = true;

function App() {
  
  return (
    <>
      <ToastContainer />

      <Routes />

      <GlobalStyles />
    </>
  );

}

export default App;
