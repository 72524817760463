import React from 'react';
import { FiAlertCircle } from 'react-icons/fi'

import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';

import { Container, ContainerInput, Headerinput, Title, Error } from './styles';

const Input : React.FC = ({
    name, type, placeholder, title, register, errors, setData, disabled, value, focus, mask, onBlur, buttonFunction
}) => {
    return (
        <>  
            <Container>
                <Headerinput>
                    <Title>{title}:</Title>
                </Headerinput>
                <ContainerInput>
                    <div className="p-inputgroup">
                        <InputMask 
                            className="form-control" 
                            mask={mask}  
                            name={name}  
                            value={value} 
                            onChange={setData} 
                            autoFocus={focus} 
                            disabled={disabled} 
                            onBlur={onBlur} 
                        /> 
                        {buttonFunction? 
                            <Button 
                                icon="pi pi-search" 
                                className="p-button-primary"
                                onClick={buttonFunction}
                                disabled={disabled}
                            />
                        : ""
                        }
                    </div>
                    {/* <input 
                        className="form-control form-control-lg" 
                        name={name} 
                        type={type} 
                        ref={register} 
                        placeholder={placeholder} 
                        onChange={setData} 
                        disabled={disabled} 
                        autoFocus={focus} 
                        value={value} 
                        step="any"
                    /> */}
                    {errors && <FiAlertCircle size={20} />}
                </ContainerInput>
                <Error>{errors?.message}</Error>
            </Container>
        </>
    )
}

export default Input