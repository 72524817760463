import React from 'react';
import { FiAlertCircle } from 'react-icons/fi'

import { InputNumber } from 'primereact/inputnumber';

import { Container, ContainerInput, Headerinput, Title, Error } from './styles';

const Input : React.FC = ({
    name, type, placeholder, title, register, errors, setData, disabled, value, focus
}) => {
    return (
        <>  
            <Container>
                {title && 
                <Headerinput>
                    <Title>{title}:</Title>
                </Headerinput>
                }
                <ContainerInput>
                    <InputNumber  mode="currency" currency="BRL" locale="pt-BR" type={type}  name={name}  value={value} onValueChange={setData} autoFocus={focus} disabled={disabled}/>
                    {errors && <FiAlertCircle size={20} />}
                </ContainerInput>
                <Error>{errors?.message}</Error>
            </Container>
        </>
    )
}

export default Input
