import React, {useEffect,  useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import InputText from '../../../components/form/primeReact/inputText'
import InputMask from '../../../components/form/primeReact/inputMask'
import ButtonPrime from '../../../components/form/primeReact/button'
import SelectPrime from '../../../components/form/primeReact/select'

import { Container } from './styles'

const NovoEquipamento : React.FC = ({ idCliente }) =>{
    const [loading, setLoading] = useState(false)
    
    const [toggleResp, setToggleResp] = useState(false)
    const [responsaveis, setResponsaveis] = useState([])
    const [respTecnico, setRespTecnico]= useState('')

    const [nome, setNome] = useState('')
    const [fabricante, setFabricante] = useState('');
    const [modelo, setModelo] = useState('')
    const [serie, setSerie] = useState('')
    const [localFisico, setLocalFisico]= useState('')

    const [responsavel, setResponsavel]= useState('')
    const [telResponsavel, setTelResponsavel]= useState('')
    const [emailResponsavel, setEmailResponsavel]= useState('')

    // const  idCliente = idCliente;
    // const  idEquipamento = idEquipamento;

    // const {perfil : perfilLogged} = JSON.parse(localStorage.getItem('user') || '');    

    useEffect(() => {
        async function handle_getClientes(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${idCliente}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setResponsaveis(response?.data?.result?.contatos_cliente);
                    // setResponsaveis(response?.data?.result?.contatos_cliente?.map(responsavel => {
                    //     return {'value': responsavel.id, 'label': responsavel.contato?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    //         return a.toUpperCase();
                    //     }) + ' - ' + responsavel.cargo}
                    // }));
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                }).finally(() => setLoading(false));
        }

        handle_getClientes();
    }, [idCliente])

    function handleSalvarEquipamento(event){
        event.preventDefault()
        setLoading(true)

        const token = sessionStorage.getItem('token');
        try {
            api.post(`/clientes/${idCliente}/equipamento`,
            {
                nome,
                fabricante,
                modelo,
                serie,
                responsavel_tecnico: responsavel,
                telefone_tecnico: telResponsavel,
                email_tecnico: emailResponsavel,
                local_fisico: localFisico
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 201)
                    toast.success('Equipamento cadastrado com sucesso');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
    }

    useEffect(()=>{
        function handle_getResponsaveis(){
            console.log(respTecnico)
            const resp = responsaveis?.filter((respo) => respo.uuid === respTecnico)

            setResponsavel(resp[0]?.contato)
            setTelResponsavel(resp[0]?.telefone1?.replace(/\D/g, ""))
            setEmailResponsavel(resp[0]?.email)

            // console.log(responsaveis, resp)
        }

        handle_getResponsaveis()
    }, [respTecnico])

    //   function handleEditarSenhaTexto(){
    //     setEditSenhaTexto(editSenhaTexto === 'text' ? 'password' : 'text')
    //   }

    return <>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Cadastro de Equipamento"}>
            {/* <NavLink to="/users/novo" className="btn btn-primary mb-2"><MdPersonAdd fontSize="24px" /> Novo Usuário</NavLink> */}
        </Title>
        <Card width="100%">
            <Container>                
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <InputText
                        title={'Nome Equipamento'} 
                        name="nomeEquipamento" 
                        value={nome}
                        setData={event => setNome(event.target.value)}
                        focus={true}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Fabricante'} 
                        name="fabricante" 
                        value={fabricante}
                        setData={event => setFabricante(event.target.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Modelo'} 
                        name="modelo" 
                        value={modelo}
                        setData={event => setModelo(event.target.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Série'} 
                        name="serie" 
                        value={serie}
                        setData={event => setSerie(event.target.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Local Físico'} 
                        name="localFisico" 
                        value={localFisico}
                        setData={event => setLocalFisico(event.target.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-4">
                    {toggleResp? 
                            <SelectPrime 
                                title={'Responsável Técnico'} 
                                name="responsavelTecnico"
                                data={responsaveis?.map(responsavel => {
                                    return {'value': responsavel.uuid, 'label': responsavel.contato?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                        return a.toUpperCase();
                                    }) + ' - ' + responsavel.cargo}
                                })}
                                value={respTecnico}
                                setData={event => setRespTecnico(event.value)}
                            />
                        : 
                            <InputText
                                title={'Responsável Técnico'} 
                                name="responsavelTecnico" 
                                value={responsavel}
                                setData={event => setResponsavel(event.target.value)}
                                buttonFunction={(event) => setToggleResp(current => !current)}
                                buttonIcon={'user-edit'}
                            />
                        }
                </div>
                <div className="p-field p-col-12 p-sm-4">
                    <InputMask
                        title={"Telefone Responsável"}
                        name={"telefoneResponsavel"}
                        value={telResponsavel}
                        setData={event => setTelResponsavel(event.target.value)}
                        mask={"(99) 9 9999-9999"}
                    />  
                </div>      
                <div className="p-field p-col-12 p-sm-4">
                    <InputText
                        title={'E-mail Técnico'} 
                        name="emailTecnico" 
                        value={emailResponsavel}
                        setData={event => setEmailResponsavel(event.target.value)}
                    />
                </div>     
            </div>
              <ButtonPrime onClick={handleSalvarEquipamento} text="Salvar" icon="save"/>
              
            </Container>
        </Card>
            </>
}

export default NovoEquipamento;