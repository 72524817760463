import React, { useState,  useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import './index.css';
import 'primereact/resources/primereact.css';

const Table = ({data, sortField, sortOrder , header, paginator, className, children}) => { 
    const [globalFilter, setGlobalFilter] = useState('');

    const dt = useRef(null);
    // const exportColumns = data.map(col => ({ title: col.header, dataKey: col.field }));

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    // const exportPdf = () => {
    //     import('jspdf').then(jsPDF => {
    //         import('jspdf-autotable').then(() => {
    //             const doc = new jsPDF.default(0, 0);
    //             doc.autoTable(exportColumns, data);
    //             doc.save('products.pdf');
    //         })
    //     })
    // }

    // const exportExcel = () => {
    //     import('xlsx').then(xlsx => {
    //         const worksheet = xlsx.utils.json_to_sheet(data);
    //         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //         saveAsExcelFile(excelBuffer, 'products');
    //     });
    // }

    // const saveAsExcelFile = (buffer, fileName) => {
    //     import('file-saver').then(FileSaver => {
    //         let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //         let EXCEL_EXTENSION = '.xlsx';
    //         const data = new Blob([buffer], {
    //             type: EXCEL_TYPE
    //         });
    //         FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //     });
    // }
    const headerTable = (
        <>
        <div className="table-header">
            {/* <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} /> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisa tabela" />
            </span>
                <div className="p-d-flex p-ai-center export-buttons">
                <Button type="button" icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" />
                {/* <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" /> */}
                {/* <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" /> */}
                <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info p-ml-auto" data-pr-tooltip="Selection Only" />
            </div>
        </div>
        </>
    );

    return (
        <div className="datatable-filter-demo"> 
            <div className="card">


                <DataTable  
                    ref={dt}
                    value={data}
                    dataKey="id" 
                    header={header=== false? false : headerTable}
                    className={'p-datatable-customers p-datatable-sm'}
                    rowHover 
                    // sortMode="multiple"
                    resizableColumns 
                    columnResizeMode="fit"
                    globalFilter={globalFilter}
                    paginator={paginator=== false? false : true} 
                    sortField="id"
                    sortOrder={-1}
                    emptyMessage={false}
                    rows={12} 
                    filterDisplay="menu"
                    responsiveLayout="stack"
                    breakpoint="400px"
                    rowsPerPageOptions={[12,20,50]}
                >
                {children}
                </DataTable>

            </div>
        </div>
    );
}
 
export default Table;