import React from 'react'


// C
    export function cidade(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">CIDADE</span>
                <span>{rowData?.cidade?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }
    export function clienteFantasiaTemplate(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">FANTASIA</span>
                <span>{rowData?.cliente_os.fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }
    export function clienteOrcamentoTemplate(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">FANTASIA</span>
                <span>{rowData?.cliente_orcamento.fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }
    export function condPagtoNomeTemplate(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">CONDI. PAGAMENTO</span>
                <span>{rowData?.cond_pagto?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }
// C

// F
export function  fimAtendimentoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">FIM ATENDIMENTO</span>
            <span >{rowData.fim_atendimento ? new Date(rowData.fim_atendimento).toLocaleDateString('en-GB') + ' '+ new Date(rowData.fim_atendimento).toLocaleTimeString('en-GB') : undefined}</span>
        </React.Fragment>
    );
}
export function  formaPagtoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">FORMA PAGAMENTO</span>
            <span>{rowData?.forma_pagto?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
        </React.Fragment>
    );
}
// F

// G
export function  garantiaTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">GARANTIA</span>
            <span>{rowData?.periodo_garantia
                                            + ' '+ rowData?.garantia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
        </React.Fragment>
    );
}
// G

// I
export function  inicioAtendimentoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">INICIO ATENDIMENTO</span>
            <span >{rowData.inicio_atendimento ? new Date(rowData.inicio_atendimento).toLocaleDateString('en-GB') + ' '+ new Date(rowData.inicio_atendimento).toLocaleTimeString('en-GB') : undefined}</span>
        </React.Fragment>
    );
}
// I

// M
export function equipamentoChamadoNomeTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">MODELO</span>
            <span>{rowData?.equipamento_os.nome?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}</span>
        </React.Fragment>
    );
}
// M

// F
    export function fantasia(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">FANTASIA</span>
                <span>{rowData?.fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }
    export function fabricanteTemplate(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">FABRICANTE</span>
                <span>{rowData?.fabricante?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }    
// F

// M
export function modeloTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">MODELO</span>
            <span>{rowData?.modelo?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}</span>
        </React.Fragment>
    );
}
// M

// R
    export function razao_social(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">RAZÃO SOCIAL</span>
                <span>{rowData?.razao_social?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }
    export function responsavelTemplate(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">RESPONSÁVEL</span>
                <span>{rowData?.responsavel_tecnico?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            })}</span>
            </React.Fragment>
        );
    }    
// R

// S
export function serieTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">SÉRIE</span>
            <span>{rowData?.serie?.toUpperCase()}</span>
        </React.Fragment>
    );
}
export function supervisorTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">SUPERVISOR</span>
            <span>{rowData?.user_supervisor?.name.toUpperCase()}</span>
        </React.Fragment>
    );
}
export function  statusTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">STATUS</span>
            <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>
        </React.Fragment>
    );
}
export function  statusChamadoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">STATUS</span>
            <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>
        </React.Fragment>
    );
}
// S

// T
export function  tipoOrcamentoTemplate(rowData){
    return (
        <React.Fragment>
            {/* <span className="p-column-title">TIPO</span> */}
            <span className={`customer-badge tipo-${rowData.tipo.toLowerCase()}`}>{rowData.tipo}</span>
        </React.Fragment>
    );
}

export function tituloOcorrenciaTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">TÍTULO</span>
            <span>{rowData?.titulo_ocorrencia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}</span>
        </React.Fragment>
    );
}   
export function tituloTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">TÍTULO</span>
            <span>{rowData?.titulo?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}</span>
        </React.Fragment>
    );
}   
export function tipoManutencaoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">TÍTULO</span>
            <span>{rowData?.tipo_manutencao?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}</span>
        </React.Fragment>
    );
}   
export function tecnicoChamadoNomeTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">TÍTULO</span>
            <span>{rowData?.tecnico_os.name?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}</span>
        </React.Fragment>
    );
}   
// T

// U
    export function uf(rowData){
        return (
            <React.Fragment>
                <span className="p-column-title">UF</span>
                <span>{rowData?.uf?.toUpperCase()}</span>
            </React.Fragment>
        );
    }
// U

// V
export function  validadeOrcamentoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">VALIDADE</span>
            <span >{rowData.validade ? new Date(rowData.validade).toLocaleDateString('pt-br') : undefined}</span>
        </React.Fragment>
    );
}
export function valorTotalTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">VALIDADE</span>
            <span >{rowData.valor_total ? rowData.valor_total.toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' }) : undefined}</span>
        </React.Fragment>
    );
}
// V



export function categoriaTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">CATEGORIA</span>
            <span>{rowData.categoria}</span>
        </React.Fragment>
    );
}
export function contaTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">CONTA</span>
            <span>{rowData.conta}</span>
        </React.Fragment>
    );
}


export function pesoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">PESO</span>
            <span>{rowData.peso}</span>
        </React.Fragment>
    );
}


export function tickerTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">TICKER</span>
            <span>{rowData.ticker}</span>
        </React.Fragment>
    );
}

export function notaTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">NOTA</span>
            <span>{rowData.nota}</span>
        </React.Fragment>
    );
}


export function setorTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">SETOR</span>
            <span>{rowData.setor}</span>
        </React.Fragment>
    );
}


export function pilarTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">PILAR</span>
            <span>{rowData.user_pilar.categoria}</span>
        </React.Fragment>
    );
}

export function  idTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">ID</span>
            <span>{rowData.id}</span>
        </React.Fragment>
    );
}
export function  instituicaoTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">INSTITUIÇÃO</span>
            <span>{rowData.instituicao}</span>
        </React.Fragment>
    );
}

export function  nameTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">NOME</span>
            <span >{rowData.name ? rowData.name : rowData.nome}</span>
        </React.Fragment>
    );
}
export function  emailTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">E-MAIL</span>
            <span >{rowData.email}</span>
        </React.Fragment>
    );
}
export function  perfilTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">PERFIL</span>
            <span >{rowData.perfil}</span>
        </React.Fragment>
    );
}


export function dtOperacaoTemplate(data){
    return (
        <React.Fragment>
            <span className="p-column-title">DATA OPERAÇÃO</span>
            <span >{new Date(data.data_operacao).toLocaleDateString('en-GB') + ' '+ new Date(data.data_operacao).toLocaleTimeString('en-GB')}</span>
            {/* <NavLink to="/users/novo" >link</NavLink> */}
        </React.Fragment>
    );
}
export function createdTemplate(data){
    return (
        <React.Fragment>
            <span className="p-column-title">DATA CRIAÇÃO</span>
            <span >{new Date(data.createdAt).toLocaleDateString('en-GB') + ' '+ new Date(data.createdAt).toLocaleTimeString('en-GB')}</span>
            {/* <NavLink to="/users/novo" >link</NavLink> */}
        </React.Fragment>
    );
}

export function  updatedTemplate(rowData){
    return (
        <React.Fragment>
            <span className="p-column-title">DATA ATUALIZAÇÃO</span>
            <span >{new Date(rowData.updatedAt).toLocaleDateString('en-GB') + ' '+ new Date(rowData.updatedAt).toLocaleTimeString('en-GB')}</span>
        </React.Fragment>
    );
}