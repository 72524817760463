/* eslint-disable react/style-prop-object */
import React, {useEffect} from 'react';

import {Container} from './styles';

const Error404: React.FC = () => {    
    useEffect(() => {
        function onLoad(){
            const position = document.documentElement;
            position.addEventListener("mousemove", e => {
                position.style.setProperty('--x', e.clientX + 'px');
            })
        }

        onLoad()
    }, [])
    return (
        <>
        <Container>
            <div className="text">
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                <h2>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                    <span>404 Not Found</span>
                </h2>
                
            </div>
        </Container>
        </>
    );
}

export default Error404;