import React from 'react';

// import { Container } from './styles';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js'

ChartJS.register(
    ChartDataLabels,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
)

const Grafico: React.FC = ({labels, data}) => {
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        showAllTooltips: true,
        indexAxis: 'y',
        scales:{
            xAxis: {
                display: false
            },
            yAxis: {
                display: true,
            }
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                backgroundColor: '#fff',
                borderRadius: '100',
                opacity: '.9',
                anchor: 'start',
                align :'right',
            }
        },
        elements: {
            point: {
                radius: 0
            }
        },
        interaction: {
            mode: 'nearest',
            intersect: false
          },
        tooltip: {
            enabled: true,
        }
    }

    const chartData = {
        labels,
        datasets: [
            {
                data,
                label: 'Chamados',
                fill: true,
                backgroundColor: [  '#4dc9f6','#f67019','#f53794','#537bc4','#acc236','#166a8f','#00a950','#58595b','#8549ba'],
                tension: 0.5,
                pointStyle: 'circle',
                pointRadius: 5,
                showAllTooltips: true
            }
        ]
    }
    return (
        <>
            <Bar options={chartOptions} data={chartData} />
        </>
    );
}

export default Grafico;