import styled from 'styled-components';

export const Container = styled.div`
    position: relative;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Content = styled.div`
    display: flex;
    flex: 1;

    margin-bottom: 10px;
    align-items:center;
    justify-content: space-between;

    label{
        width: 60px;
    }

    input{
        margin-left: 10px;
        width: 450px;
    }
`
