import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { PowerOff } from 'styled-icons/fa-solid'

export const Loggoff = styled(PowerOff)`
    width: 20px;
    height: 20px;

    color: #0f0;
    cursor: pointer;
    margin-left: -100px;
    transition: all 0.2s ease 0s;

    :hover {
        color: #f00;
        text-shadow: 0 0 20px #f00;
    }
`;

export const Container = styled.aside`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom right, var(--secondary), var(--primary));
    flex-direction: column;

    white-space: nowrap;
    text-overflow: ellipsis;

    transition: all 0.2s ease 0s;

    /* &:hover {
        width: 200px;
    } */

    &:hover ${Loggoff} {
        margin-left: 0px;
    } 
    /* &:hover .secnav {
        margin-left: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
    } */

    .logo{
        height: 60px;
        padding: 0 5px;        
    }
`;

export const Logo = styled.img`
    align-items:center;
    justify-content: center;
    height: 60px;
    width: 100%;
    flex-shrink: 0;
    /* padding: 2px 0; */
    object-fit: contain;
    padding: 2px;

    
`;

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    padding: 5px 0px 0px 5px;
    margin-top: 5vh;

    overflow-y: auto;
    z-index: 1;

    font-size: .9rem;

    .group{
        margin-bottom: 10px;
        background: rgba(0, 0, 0, .2);
    }


        > ul {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
        li {
            /* font-weight: 400; */
            position: relative;
            /* margin-left: 5px; */
            &:hover {
                background-color: rgba(68, 114, 196, 0.5);
            }
            .tag {
                color: var(--white);
                background-color: rgba(68, 114, 196, 0.8);
                padding: 0 .5em;
                border-radius: 2em;
                margin-left: auto;
                margin-right: .75em;
            }
            a {
                position: relative;
                color: var(--white);
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-decoration: none;  
                padding: 1px 10px;

                >span{
                    margin-left: 5px;
                }
            }
            
        }    
        .icon {
            height: 20px;
            flex-shrink: 0;
            width: 20px;
            padding: 19px;
            margin-right: 5px;
            padding-bottom: 15px;
            color: #0f0;
        } 
    
    .secnav {
        border-left: 1px solid var(--white);
        overflow: hidden;
        margin-left: 30px;

        font-size: .8rem;

        li {
            /* max-height: 100px; */
            
            a {
                text-transform: initial;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                /* color: inherit; */
                padding: 1px 10px;
            }
        }
    }
`

export const SidebarLink = styled(NavLink)`
    width: 100%;
    height: 100%;
    margin-top: 3px;

    /* text-align: center; */
    /* margin-left: 20px; */
    text-decoration: none;
    color: #fff;

    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    position: relative;

    &.active {
        color: #fff;
        border-right: 3px solid var(--white);
        background-color: rgba(0, 0, 0, .4);
        text-transform: uppercase;
    }
    
    &:hover {
        text-decoration: none;
        color: #fff;
        background-color: rgba(0, 0, 0, .1);
    }
`;

export const UserName = styled.div`
    
    > strong{
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 

        font-size: 13px;
        font-weight: bold;
    }
`

export const InfoUser = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
    width: 100%;
    /* height: 7.5%; */
    
    padding: 10px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: rgba(0, 0, 0, .2) 0px 1px 0px 0px;

    &:hover ${UserName}{
        color: #fff
    }
`
export const Profile = styled.div`
    display: flex;
    flex-direction: column;

    align-items: left;
    justify-content: center;

    color: rgba(255, 255, 255, .7);

    width: 90px;
`

export const Title = styled.div`
    font-size: 10px;    
`

