import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import InputText from '../../../components/form/primeReact/inputText'
import InputNumber from '../../../components/form/primeReact/inputNumber'
import TextAreaPrime from '../../../components/form/primeReact/textArea'
import SelectPrime from '../../../components/form/primeReact/select'
import ButtonPrime from '../../../components/form/primeReact/button'

import Table from '../../../components/table'
import { tipoOrcamentoTemplate } from '../../../components/table/service'
import { Column } from 'primereact/column';

import { Container, CondPagtoStyle } from './styles'

const NovoOrcamento : React.FC = ({ idCliente }) =>{
    const [loading, setLoading] = useState(false)

    const [visibleAddIten, setVisibleAddIten] = useState(false);
    const [visibleEditIten, setVisibleEditIten] = useState(false);
    const [clientes, setClientes] = useState([]);

    const [cliente, setCliente] = useState('');
    const [titulo, setTitulo] = useState('');
    const [dtValidade, setDtValidade] = useState('');
    const [valor, setValor] = useState('');
    const [desconto, setDesconto] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [condPagto, setCondPagto] = useState('À Vista');
    const [tipoSinal, setTipoSinal] = useState('');
    const [valorSinal, setValorSinal] = useState('');
    const [restanteSinal, setRestanteSinal] = useState('');
    const [parcelas, setParcelas] = useState('');
    const [formaPagto, setFormaPagto] = useState(['Dinheiro']);
    const [garantia, setGarantia] = useState('');
    const [periodoGarantia, setPeriodoGarantia] = useState('');
    const [condicoesGarantia, setCondicoesGarantia] = useState('');
    const [anotacoes, setAnotacoes] = useState('');
    const [obs, setOBS] = useState('');

    const [responsaveis, setResponsaveis] = useState([]);
    const [responsavel, setResponsavel] = useState('');    

    const [itens, setItens] = useState([
        // {'item': 'parafuso df', 'unidade_medida': 'un', 'quantidade': '2', 'preco_unit':'2', 'preco': '4', 'desconto': '0.5', 'total': '3.5', 'tipo': 'material'},
    ]);
    const [idItem, setIdItem] = useState(0);
    const [itensitem, setItensItem] = useState('');
    const [itensunidMedida, setItensUnidMedida] = useState('');
    const [itensquantidade, setItensQuantidade] = useState('');
    const [itensprecoUnit, setItensPrecoUnit] = useState('');
    // const [itenspreco, setItensPreco] = useState('');
    const [itensitemDesconto, setItensItemDesconto] = useState('')
    // const [itenstotal, setItensTotal] = useState('')
    const [itenstipo, setItensTipo]=useState('');


    const tipoProduto = [{value: 'Material', label: 'Material'},{value: 'Serviço', label: 'Serviço'}]
    const unidadeMedida = [{value: 'un. (unidade)', label: 'un. (unidade)'},{value: 'm² (metros quadrados)', label: 'm² (metros quadrados)'},{value: 'km² (quilômetros quadrados)', label: 'km² (quilômetros quadrados)'},{value: 'mm (milímetros)', label: 'mm (milímetros)'},{value: 'cm (centímetros)', label: 'cm (centímetros)'},{value: 'm (metros)', label: 'm (metros)'},{value: 'km (quilômetros)', label: 'km (quilômetros)'},{value: 'mL (mililitros)', label: 'mL (mililitros)'},{value: 'L (litros)', label: 'L (litros)'},{value: 'm³ (metros cúbicos)', label: 'm³ (metros cúbicos)'},{value: 'Min (minutos)', label: 'Min (minutos)'},{value: 'H (horas)', label: 'H (horas)'},{value: 'Dias', label: 'Dias'},{value: 'Semanas', label: 'Semanas'},{value: 'Meses', label: 'Meses'},{value: 'g', label: 'g (gramas)'},{value: 'kg', label: 'kg (quilogramas)'}]

    useEffect(() => {
        async function handle_getClientes(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get('/clientes', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setClientes(response.data.result.map(cliente => {
                        return {'value': cliente.uuid, 'label': cliente?.id +" - "+ cliente.fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        })}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                        }else{
                        toast.error(error.message)
                        }
                });
            setLoading(false)
    }

        handle_getClientes()
    }, [])

    useEffect(() => {
        async function handle_getCliente(){
            setLoading(true)
            
            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${cliente}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    
                    // setResponsaveis('');
                    setResponsaveis(response?.data?.result?.contatos_cliente?.map(responsavel => {
                        return {'value': responsavel.uuid, 'label': responsavel.contato?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        }) + ' - ' + responsavel.cargo}
                    }));
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                }).finally(() => setLoading(false));
        }

        if (cliente)
            handle_getCliente();
    }, [cliente])

    function handleSalvarOrcamento(event){
        event.preventDefault()
        setLoading(true)

        console.log(itens)
        const token = sessionStorage.getItem('token');
        try {
            api.post('/orcamento',
            {
                cliente_uuid: cliente,
                responsavel_cliente_uuid: responsavel,
                titulo: titulo,
                validade: dtValidade,
                valor: valor,
                desconto: desconto,
                valor_total: valorTotal,
                cond_pagto: condPagto,
                tipo_sinal: tipoSinal,
                valor_sinal: valorSinal,
                restante_sinal: restanteSinal,
                parcelas: parcelas,
                forma_pagto: formaPagto.toString(),
                garantia: garantia,
                periodo_garantia: periodoGarantia,
                condicoes_garantia: condicoesGarantia,
                anotacoes: anotacoes,
                obs: obs,
                itens: itens
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 201)
                    toast.success('Orçamento criado com sucesso');

            }).catch(error => {
                
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              })
              .finally((response) =>{
                setLoading(false)
              });
            //  setLoading(false)
        } catch (error) {
            
            toast.error(error)
        }
    }


    function handleEditAddIten(){
        var itemIndex = itens.filter((item) => item.id !== idItem)

        const newIten ={
            'id': idItem,
            'item': itensitem, 
            'unidade_medida': itensunidMedida, 
            'quantidade': itensquantidade, 
            'preco_unit':itensprecoUnit, 
            'preco': itensquantidade * itensprecoUnit, 
            'desconto': itensitemDesconto, 
            'total': (itensquantidade * itensprecoUnit) - itensitemDesconto, 
            'tipo': itenstipo,
            'd_e_l_e_t': null
        }

        setValor(itemIndex.concat(newIten).filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.preco, 0))
        setDesconto(itemIndex.concat(newIten).filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.desconto, 0))
        setValorTotal(itemIndex.concat(newIten).filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.total, 0))

        setItens(itemIndex.concat(newIten).sort(function(a,b) {return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);}))
        
    }
    function handleAddIten(){
        const newIten ={
            'id': idItem + 1,
            'item': itensitem, 
            'unidade_medida': itensunidMedida, 
            'quantidade': itensquantidade, 
            'preco_unit':itensprecoUnit, 
            'preco': itensquantidade * itensprecoUnit, 
            'desconto': itensitemDesconto, 
            'total': (itensquantidade * itensprecoUnit) - itensitemDesconto, 
            'tipo': itenstipo,
            'new': 1
        }

        setIdItem(idItem+ 1);
        setItensItem('');
        setItensUnidMedida('');
        setItensQuantidade('');
        setItensPrecoUnit('');
        // setItensPreco('');
        setItensItemDesconto('');
        // setItensTotal('');
        setItensTipo('');        

        setValor(itens.concat(newIten).filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.preco, 0))
        setDesconto(itens.concat(newIten).filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.desconto, 0))
        setValorTotal(itens.concat(newIten).filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.total, 0))

        setItens((prevVals) => [...prevVals,newIten])
        // console.log(itens)
    }
    function handleDeleteItem(data){
        let newItens = itens?.map(iten => {return {...iten, d_e_l_e_t: iten.id === data.id ? '*' : iten.d_e_l_e_t}});

        
        // setValor(valor *1 - (data.quantidade * data.preco_unitario))
        setValor(newItens.filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.preco, 0))
        setDesconto(newItens.filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.desconto, 0))
        setValorTotal(newItens.filter(iten => (iten.d_e_l_e_t !== '*'))?.reduce((total, iten) => total + iten.total, 0))
        setItens(newItens)
    }
    function handleEditItem(data){
        
        let item = itens.filter((item) => item.id === data.id)
        // console.log(data)

        setIdItem(item[0].id)
        setItensItem(item[0].item);
        setItensUnidMedida(item[0].unidade_medida);
        setItensQuantidade(item[0].quantidade *1);
        setItensPrecoUnit(item[0].preco_unit *1);
        // setItensPreco(item[0].item);
        setItensItemDesconto(item[0].desconto *1);
        // setItensTotal('');
        setItensTipo(item[0].tipo);

        setVisibleEditIten(true)
    }
    function onVisibleAddIten(){
        setVisibleAddIten(true)

    }
    function onHideAddIten(){
        setItensItem('');
        setItensUnidMedida('');
        setItensQuantidade('');
        setItensPrecoUnit('');
        // setItensPreco('');
        setItensItemDesconto('');
        // setItensTotal('');
        setItensTipo('');

        setVisibleAddIten(false)
        setVisibleEditIten(false)
    } 
    const onformaPagtoChange = (e) => {
        let selectedFormaPagto = [...formaPagto];

        if (e.checked)
            selectedFormaPagto.push(e.value);
        else
            selectedFormaPagto.splice(selectedFormaPagto.indexOf(e.value), 1);

        setFormaPagto(selectedFormaPagto);
    }
    const actionBodyTemplate = (data) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <>
                    <Button icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-success" onClick={(e) => handleEditItem(data)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" onClick={(e) => handleDeleteItem(data)} />
                </>     
            </div>
        );
    }     

    return <>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Editar Orçamento"}>
            {/* <NavLink to="/users/novo" className="btn btn-primary mb-2"><MdPersonAdd fontSize="24px" /> Novo Usuário</NavLink> */}
        </Title>
        <Sidebar visible={visibleAddIten || visibleEditIten} style={{width:'40%'}} position="right" onHide={() => onHideAddIten()}>
            <Container> 
                <div className="p-field p-col-12">
                    <SelectPrime 
                        title={'Tipo'} 
                        name={'tipo'} 
                        data={tipoProduto} 
                        value={itenstipo}
                        setData={event => setItensTipo(event.value)}
                        focus={true}
                    />
                </div> 
                <div className="p-field p-col-12">
                    <InputText
                        title={"Descrição"}
                        name={"tituloOrcamento"}
                        type={"text"}
                        value={itensitem}
                        setData={event => setItensItem(event.target.value)}
                    />
                </div>
                <div className="p-field p-col-12">
                    <SelectPrime 
                        title={'Unidade de Medida'} 
                        name={'tipo'} 
                        data={unidadeMedida} 
                        value={itensunidMedida}
                        setData={event => setItensUnidMedida(event.value)}
                        focus={true}
                    />
                </div> 
                {/* <CondPagtoStyle> */}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <InputText
                            title={"Quantidade"}
                            name={"quantidade"}
                            type={"number"}
                            value={itensquantidade}
                            setData={event => setItensQuantidade(event.target.value)}
                        />
                    </div>
                    <div className="p-field p-col">
                    <InputNumber
                        title={"Preço Unitário"}
                        name={"precoUnitario"}
                        // type={"number"}
                        value={itensprecoUnit}
                        setData={event => setItensPrecoUnit(event.target.value)}
                    /> 
                    </div>
                    <div className="p-field p-col">
                        
                        <InputNumber
                            title={"Valor"}
                            name={"precoUnitario"}
                            type={"text"}
                            value={itensquantidade * itensprecoUnit}
                            disabled
                            // setData={event => setItensPreco(itensquantidade * itensprecoUnit)}
                        />
                    </div>

                </div>

                {/* </CondPagtoStyle> */}
                {/* <CondPagtoStyle> */}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <InputNumber
                            title={"Desconto"}
                            name={"desconto"}
                            type={"text"}
                            value={itensitemDesconto}
                            setData={event => setItensItemDesconto(event.target.value)}
                        />
                    </div>
                    <div className="p-field p-col">
                        <InputNumber
                            title={"Valor Final"}
                            name={"valorFinal"}
                            type={"text"}
                            value={(itensquantidade * itensprecoUnit) - itensitemDesconto}
                            disabled
                            // setData={event => setItensPreco(itensquantidade * itensprecoUnit)}
                        />
                    </div>

                </div>
                {/* </CondPagtoStyle> */}
                {!visibleEditIten ? 
                    <Button icon="pi pi-plus" className="p-button-sm p-button-rounded p-button-success" onClick={handleAddIten} />
                :
                    <Button icon="pi pi-pencil" className="p-button-sm p-button-rounded p-button-success" onClick={handleEditAddIten} />
                }
            </Container>
        </Sidebar>

        <Card width="100%">
            <Container>                
                <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-sm-6">
                        <SelectPrime 
                            title={'Cliente'} 
                            name={'cliente'} 
                            data={clientes} 
                            value={cliente}
                            setData={event => setCliente(event.value)}
                        />
                    </div>  
                    <div className="p-field p-col-12 p-sm-6">
                        <SelectPrime 
                            title={'Responsável'} 
                            name={'responsavel'} 
                            data={responsaveis} 
                            value={responsavel}
                            setData={event => setResponsavel(event.value)}
                        />
                    </div> 

                    <div className="p-field p-col-12 p-sm-6">
                        <InputText
                            title={"Título"}
                            name={"tituloOrcamento"}
                            type={"text"}
                            value={titulo}
                            setData={event => setTitulo(event.target.value)}
                        />
                    </div>
                

                    <div className="p-field p-col-12 p-sm-6">
                        <InputText
                            title={"Validade Orçamento"}
                            name={"validadepOrçamento"}
                            type={"date"}
                            value={dtValidade}
                            setData={event => setDtValidade(event.target.value)}
                        />
                    </div>

                    <div className="p-field p-col-12">
                        <Accordion activeIndex={'0'}>
                            <AccordionTab header={`Pedido ${valorTotal? ' - [R$ ' + valorTotal.toLocaleString() +']': ''} `}>
                            {/* <Button icon="pi pi-plus" className="p-button-sm p-button-rounded p-button-success" onClick={onVisibleAddIten} /> */}
                                <div className="p-field p-col">
                                    <Table data={itens.filter((iten) => iten.d_e_l_e_t !== '*')} header={false} paginator={true} className={false} >
                                        {/* <Column field={'id'}        header={'ID'}               sortable filter={false} width="70px" /> */}
                                        {/* <Column field={'d_e_l_e_t'}        header={'d_e_l_e_t'}               sortable filter={false} width="70px" /> */}
                                        <Column field={'item'}      header={'DESCRIÇÃO'}  style={{width:'20%'}}    />
                                        <Column field={'unidade_medida'}      header={'UN'}         />
                                        <Column field={'quantidade'}      header={'QTDADE'}         />
                                        <Column field={'preco_unit'}      header={'VALOR UNIT.'}         />
                                        <Column field={'preco'}      header={'VALOR'}         />
                                        <Column field={'desconto'}      header={'DESC.'}         />
                                        <Column field={'total'}      header={'TOTAL'}         />
                                        <Column field={'tipo'}      header={'TIPO'}   body={tipoOrcamentoTemplate}   style={{width:'6rem'}}   />
                                        <Column body={actionBodyTemplate} style={{width:'12%'}} header={<Button icon="pi pi-plus" className="p-button-sm p-button-rounded p-button-primary" onClick={onVisibleAddIten} />} ></Column>
                                    </Table>
                                </div>  
                            </AccordionTab>
                            <AccordionTab header={`Condições de pagamento ${condPagto? '- ' + condPagto : ''} `}>
                              <CondPagtoStyle>

                                <div className="p-field p-col-4">
                                    <div className="field-radiobutton">
                                        <RadioButton 
                                            inputId="aVista" 
                                            name="aVista" 
                                            value="À Vista" 
                                            onChange={(e) => setCondPagto(e.value)} 
                                            checked={condPagto === 'À Vista'} 
                                        />
                                        <label htmlFor="aVista">À Vista</label>
                                    </div>
                                </div>
                                <div className="p-field p-col-4">
                                    <div className="field-radiobutton">
                                        <RadioButton 
                                            inputId="Sinal" 
                                            name="Sinal" 
                                            value="Sinal" 
                                            onChange={(e) => setCondPagto(e.value)} 
                                            checked={condPagto === 'Sinal'} 
                                        />
                                        <label htmlFor="Sinal">Sinal</label>
                                    </div>
                                </div>
                                <div className="p-field p-col-4">
                                    <div className="field-radiobutton">
                                        <RadioButton 
                                            inputId="Parcelas" 
                                            name="Parcelas" 
                                            value="Parcelas" 
                                            onChange={(e) => setCondPagto(e.value)} 
                                            checked={condPagto === 'Parcelas'}
                                         />
                                        <label htmlFor="Parcelas">Parcelas</label>
                                    </div>
                                </div>
                                
                              </CondPagtoStyle>
                                
                                {  condPagto === 'Sinal'?
                                <div>
                                    <Divider align="left" type="dashed">
                                        <span>Tipo Sinal</span>
                                    </Divider>

                                    <CondPagtoStyle>

                                        <div className="p-field p-col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton 
                                                    inputId="perc" 
                                                    name="perc" 
                                                    value="percentual" 
                                                    onChange={(e) => setTipoSinal(e.value)} 
                                                    checked={tipoSinal === 'percentual'} 
                                                />
                                                <label htmlFor="perc">Percentual</label>
                                            </div>
                                        </div>
                                        <div className="p-field p-col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton 
                                                    inputId="valor" 
                                                    name="valor" 
                                                    value="valor" 
                                                    onChange={(e) => setTipoSinal(e.value)} 
                                                    checked={tipoSinal === 'valor'} 
                                                />
                                                <label htmlFor="valor">Valor</label>
                                            </div>
                                        </div>

                                        <div className="p-field p-col-3">

                                            <InputText
                                                // title={"Título"}
                                                name={"valorSinal"}
                                                type={"number"}
                                                value={valorSinal}
                                                setData={event => setValorSinal(event.target.value)}
                                                // focus={true}
                                                />  
                                            </div>
                                    </CondPagtoStyle>
                             
                                 
                        

                                    <Divider align="left" type="dashed">
                                        <span>Valor restante</span>
                                    </Divider>

                                    <CondPagtoStyle>

                                        <div className="p-field p-col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton 
                                                    inputId="aposConclusao" 
                                                    name="aposConclusao" 
                                                    value="Após a conclusão" 
                                                    onChange={(e) => setRestanteSinal(e.value)} 
                                                    checked={restanteSinal === 'Após a conclusão'} 
                                                />
                                                <label htmlFor="aposConclusao">Após a conclusão</label>
                                            </div>
                                        </div>
                                        <div className="p-field p-col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton 
                                                    inputId="emParcelas" 
                                                    name="emParcelas" 
                                                    value="Em Parcelas" 
                                                    onChange={(e) => setRestanteSinal(e.value)} 
                                                    checked={restanteSinal === 'Em Parcelas'} 
                                                />
                                                <label htmlFor="emParcelas">Em Parcelas</label>
                                            </div>
                                        </div>

                                        {restanteSinal !== 'Em Parcelas' ? '':
                                        <div className="p-field p-col-3">
                                            <InputText
                                                // title={"Título"}
                                                name={"parcelas"}
                                                type={"number"}
                                                value={parcelas}
                                                setData={event => setParcelas(event.target.value)}
                                                // focus={true}
                                            />                 

                                        </div>
                                        }                      
                                    </CondPagtoStyle>
                                </div>
                              : condPagto === 'Parcelas' ?
                                <div className="p-field p-col">
                                    <InputText
                                        // title={"Título"}
                                        name={"parcelas"}
                                        type={"number"}
                                        value={parcelas}
                                        setData={event => setParcelas(event.target.value)}
                                        // focus={true}
                                    />                 

                                </div>
                          : ''}
                            </AccordionTab>
                        <AccordionTab header={`Meios de pagamento ${formaPagto.length > 0? '- ' + formaPagto : ''} `}>
                            <CondPagtoStyle>
                                <div className="p-field p-col">
                                    <div className="field-checkbox">
                                        <Checkbox 
                                            inputId="boleto" 
                                            name="boleto" 
                                            value="Boleto" 
                                            onChange={onformaPagtoChange} 
                                            checked={formaPagto.indexOf('Boleto') !== -1} 
                                        />
                                        <label htmlFor="boleto">Boleto</label>
                                    </div>
                                </div>
                                <div className="p-field p-col">
                                    <div className="field-checkbox">
                                        <Checkbox 
                                            inputId="dinheiro" 
                                            name="dinheiro" 
                                            value="Dinheiro" 
                                            onChange={onformaPagtoChange} 
                                            checked={formaPagto.indexOf('Dinheiro') !== -1} 
                                        />
                                        <label htmlFor="dinheiro">Dinheiro</label>
                                    </div>
                                </div>
                                <div className="p-field p-col">
                                    <div className="field-checkbox">
                                        <Checkbox 
                                            inputId="pix" 
                                            name="pix" 
                                            value="PIX" 
                                            onChange={onformaPagtoChange} 
                                            checked={formaPagto.indexOf('PIX') !== -1} 
                                        />
                                        <label htmlFor="pix">PIX</label>
                                    </div>
                                </div>
                                <div className="p-field p-col">
                                    <div className="field-checkbox">
                                        <Checkbox 
                                            inputId="cheque" 
                                            name="cheque" 
                                            value="Cheque" 
                                            onChange={onformaPagtoChange} 
                                            checked={formaPagto.indexOf('Cheque') !== -1} 
                                        />
                                        <label htmlFor="cheque">Cheque</label>
                                    </div>
                                </div>
                                
                            </CondPagtoStyle>
                            <CondPagtoStyle>
                                <div className="p-field p-col">
                                    <div className="field-checkbox">
                                        <Checkbox 
                                            inputId="cartaoCredito" 
                                            name="cartaoCredito" 
                                            value="Cartão Crédito" 
                                            onChange={onformaPagtoChange} 
                                            checked={formaPagto.indexOf('Cartão Crédito') !== -1} 
                                        />
                                        <label htmlFor="cartaoCredito">Cartão Crédito</label>
                                    </div>
                                </div>
                                <div className="p-field p-col">
                                    <div className="field-checkbox">
                                        <Checkbox 
                                            inputId="cartaoDebito" 
                                            name="cartaoDebito" 
                                            value="Cartão Débito" 
                                            onChange={onformaPagtoChange} 
                                            checked={formaPagto.indexOf('Cartão Débito') !== -1} 
                                        />
                                        <label htmlFor="cartaoDebito">Cartão Débito</label>
                                    </div>
                                </div>
                                <div className="p-field p-col">
                                    <div className="field-checkbox">
                                        <Checkbox 
                                            inputId="transfBancaria" 
                                            name="transfBancaria" 
                                            value="Transf. Bancária" 
                                            onChange={onformaPagtoChange} 
                                            checked={formaPagto.indexOf('Transf. Bancária') !== -1} 
                                        />
                                        <label htmlFor="transfBancaria">Transf. Bancária</label>
                                    </div>
                                </div>

                            </CondPagtoStyle>
                        </AccordionTab>
                        <AccordionTab header="Garantia">
                            <CondPagtoStyle>

                                <div className="p-field p-col-3">
                                    <div className="field-radiobutton">
                                        <RadioButton 
                                            inputId="Dias" 
                                            name="Dias" 
                                            value="Dias" 
                                            onChange={(e) => setGarantia(e.value)} 
                                            checked={garantia === 'Dias'} 
                                        />
                                        <label htmlFor="Dias">Dias</label>
                                    </div>
                                </div>
                                <div className="p-field p-col-3">
                                    <div className="field-radiobutton">
                                        <RadioButton 
                                            inputId="Meses" 
                                            name="Meses" 
                                            value="Meses" 
                                            onChange={(e) => setGarantia(e.value)} 
                                            checked={garantia === 'Meses'} 
                                        />
                                        <label htmlFor="Meses">Meses</label>
                                    </div>
                                </div>
                                <div className="p-field p-col-3">
                                    <div className="field-radiobutton">
                                        <RadioButton 
                                            inputId="Anos" 
                                            name="Anos" 
                                            value="Anos" 
                                            onChange={(e) => setGarantia(e.value)} 
                                            checked={garantia === 'Anos'} 
                                        />
                                        <label htmlFor="Anos">Anos</label>
                                    </div>
                                </div>
                                <div className="p-field p-col-3">

                                    <InputText
                                        // title={"Título"}
                                        name={"periodoGarantia"}
                                        type={"number"}
                                        value={periodoGarantia}
                                        setData={event => setPeriodoGarantia(event.target.value)}
                                    />  
                                </div>

                            </CondPagtoStyle>
                            <CondPagtoStyle>
                                <div className="p-field p-col-12">
                                    <TextAreaPrime
                                        title={"Condições Garantia"} 
                                        name={'condicoesGarantia'} 
                                        rows={4} 
                                        value={condicoesGarantia}
                                        setData={event => setCondicoesGarantia(event.target.value)}
                                    />  
                                </div>
                            </CondPagtoStyle>
                        </AccordionTab>
                        <AccordionTab header="Observações">
                                <div className="p-field p-col-12">
                                    <TextAreaPrime
                                        title={"Observações Gerais"} 
                                        name={'observacoesGerais'} 
                                        rows={4} 
                                        value={obs}
                                        setData={event => setOBS(event.target.value)}
                                    />  
                                </div>
                                <div className="p-field p-col-12">
                                    <TextAreaPrime
                                        title={"Anotações (não aparece para o cliente)"} 
                                        name={'anotacoes'} 
                                        rows={4} 
                                        value={anotacoes}
                                        setData={event => setAnotacoes(event.target.value)}
                                    />  
                                </div>
                        </AccordionTab>
                        </Accordion>
                    </div>

                </div>  

                <ButtonPrime onClick={handleSalvarOrcamento} text="Salvar" icon="save"/>

              
            </Container>
        </Card>
            </>
}

export default NovoOrcamento;