import styled from 'styled-components';

export const Container = styled.div`
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid var(--grey);
    padding: .7rem;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    font-size: 1em;

    width: ${props => props.width || 'auto'};
    min-width: ${props => props.minWidth === true? '350px' : props.minWidth};
    // height: ${props => props.height === true? 'auto' : props.height || 0};
    height: ${props => props.height || 'auto'};
`;