import styled from "styled-components";

export const Grid = styled.div`
    height: 100vh;
    width: 100vw;


    display: grid;
    grid-template-rows: 60px 1fr 30px;
    grid-template-columns:  1fr auto;
    grid-template-areas: "aside main"
                         "aside  main" 
                         "aside footer";
`;

export const Header = styled.div`
    grid-area: header;
    background-color: #EBEBEB;
    /* width: calc(100vw - 1px); */
`
export const Aside = styled.div`
        grid-area: aside;
        background-color: yellow;
        width:100px;

        
        transition: all 0.2s ease 0s;

        &:hover {
            width:165px;
        }

`

export const Main = styled.div`
        grid-area: main;
        /* background-color: #f7f9fc; */
        overflow: auto;
        display: flex;
        flex-direction: column;
        transition: all 0.2s ease 0s;
        width: calc(100vw - 100px);
        /* height: 100%; */
        padding: 5px 20px;
        align-items: center;
        justify-content: first baseline;
`

export const Footer = styled.div`
        grid-area: footer;
`
