import React from 'react';

import Sidebar from '../sidebar'
// import Navbar from '../navbar'
import Rodape from '../footer'

import {Grid, Aside, Main, Footer} from './styles'

function Layout({children, ...rest}) {
    return (
        <Grid {...rest}>
            <Aside>
                <Sidebar/>
            </Aside>

            {/* <Header>
                <Navbar />
            </Header> */}
            
            <Main>
                {children}
            </Main>
            
            <Footer>
                <Rodape />
            </Footer>
        </Grid>
    );
}

export default Layout;