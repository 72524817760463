import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';

import { toast } from 'react-toastify';

import api from '../../../services/api'
import axios from 'axios';


import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import SelectPrime from '../../../components/form/primeReact/select'
import InputText from '../../../components/form/primeReact/inputText'
import InputMask from '../../../components/form/primeReact/inputMask'
import ButtonPrime from '../../../components/form/primeReact/button'

import Table from '../../../components/table';
import { statusTemplate } from '../../../components/table/service'

import { Container } from './styles'

const CreateCliente : React.FC = ({ id }) =>{
    const [loading, setLoading] = useState(false)
    const [razaoSocial, setRazaoSocial] = useState('')
    const [fantasia, setFantasia] = useState('');
    const [tipoPessoa, setTipoPessoa] = useState('')
    const [cpf, setCpf] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [inscricaoEstadual, setInscricaoEstadual]= useState('')
    const [inscricaoMunicipal, setInscricaoMunicipal]= useState('')
    const [inscricaoSuframa, setInscricaoSuframa]= useState('')
    const [cnae, setCNAE]= useState('')
    const [telefone1, setTelefone1]= useState('')
    const [telefone2, setTelefone2]= useState('')
    // const [whats, setWhats]= useState(true)
    const [email, setEmail]= useState('')
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    // const [status, setStatus] = useState('');
    const [simples, setSimples] = useState('');
    const [mei, setMei] = useState('');
    const [dtAbertura, setDtAbertura] = useState('');
    const [situacaoCadastral, setSituacaoCadastral] = useState('');
    const [matrizFilial, setMatrizFilial] = useState('');
    const [contatos, setContatos] = useState([]);
    

    const [visibleEdit, setVisibleEdit] = useState(false)
    const [newId, setNewId] = useState('')
    const [newContato, setNewContato] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [newTelefone1, setNewTelefone1] = useState('')
    const [newWhats1, setNewWhats1] = useState('')
    const [newTelefone2, setNewTelefone2] = useState('')
    const [newWhats2, setNewWhats2] = useState('')
    const [newCargo, setNewCargo] = useState('')
    const [newStatus, setNewStatus] = useState('')

    const dataTipoPessoa = [{value: 'CPF', label: 'Física'},{value: 'CNPJ', label: 'Jurídica'}]
    const dataStatus = [{value: 'Ativo', label: 'Ativo'},{value: 'Inativo', label: 'Inativo'}]
    const dataYesNo = [{value: 'Sim', label: 'Sim'},{value: 'Não', label: 'Não'}]
    const dataMatrizFilial = [{value: 'Matriz', label: 'Matriz'},{value: 'Filial', label: 'Filial'}]

    // const {perfil : perfilLogged} = JSON.parse(localStorage.getItem('user') || '');

    async function handleGetEndereco(event){
        if(!cep){
            toast.error("CEP invalido")
            return false
        }

        setLoading(true)
        await axios.get(`https://viacep.com.br/ws/${cep.replace(/\D/g, '')}/json/`).then(response => {
            setLoading(false)

            if (response.data.erro){
                toast.error("CEP inexistente")

            }

            if (response.status === 200){
                setLogradouro(response.data?.logradouro)
                setComplemento(response.data?.complemento)
                setBairro(response.data?.bairro)
                setCidade(response.data?.localidade)
                setUF(response.data?.uf)

            }

        }).catch(error => {
            setLoading(false)
            if ((error.response?.data.error)){
                toast.error(error.response?.data.error)
            }else{
                toast.error("CEP invalido")
            }
            });
    }

    async function handleGetCNPJ(event){
        if(!cnpj){
            toast.error("CNPJ invalido")
            return false
        }

        setLoading(true)
        await axios.get(`https://minhareceita.org/${cnpj.replace(/\D/g, '')}`).then(response => {
                setRazaoSocial(response.data?.razao_social?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }))
                setFantasia(response.data?.nome_fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }))
                setSimples(response.data?.opcao_pelo_simples === true ? "Sim": "Não")
                setMei(response.data?.opcao_pelo_mei === true ? "Sim": "Não")
                setMatrizFilial(response.data?.descricao_identificador_matriz_filial?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }))
                setSituacaoCadastral(response.data?.situacao_cadastral === 2 ? 'Ativo' : "Inativo")
                setCNAE(response.data?.cnae_fiscal)
                setEmail(response.data?.email || '')
                setTelefone1(response.data?.ddd_telefone_1)
                setTelefone2(response.data?.ddd_telefone_2)
                setDtAbertura(response.data?.data_inicio_atividade)
                setCep(response.data?.cep)
                setLogradouro((`${response?.data?.descricao_tipo_de_logradouro} ${response?.data?.logradouro}, ${response?.data?.numero}`).toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }))
                setComplemento(response.data?.complemento?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }))
                setBairro(response.data?.bairro?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }))
                setCidade(response.data?.municipio?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    return a.toUpperCase();
                }))
                setUF(response.data?.uf)

                setInscricaoEstadual(response.data?.result?.inscricao_estadual)

        }).catch(error => {
            toast.error("Não foi possível encontrar CNPJ")
        }).finally(() => setLoading(false));
    }

    function handleCreateCliente(event){
        event.preventDefault()

        if (!razaoSocial){
            toast.error('Razão Social é Obrigatorio')
            return false
        } 
        if (!fantasia){
            toast.error('Fantasia é Obrigatorio')
            return false
        }             

        if (tipoPessoa === 'CPF' && !validaCPF(cpf.replace(/\D/g, ''))){
            toast.error('CPF Inválido')
            return false
        }
        if (tipoPessoa === 'CNPJ' && !validarCNPJ(cnpj.replace(/\D/g, ''))){
            toast.error('CNPJ Inválido')
            return false
        }

        setLoading(true)

        const token = sessionStorage.getItem('token');
        try {
            api.post(`/clientes`,
            {
                razao_social: razaoSocial,
                fantasia,
                tipo_pessoa: tipoPessoa === 'CPF' ? 'CPF' : 'CNPJ',
                cpf_cnpj: tipoPessoa === 'CPF' ? cpf : cnpj,
                inscricao_estadual: inscricaoEstadual,
                cnae,
                telefone_fixo: telefone1,
                celular: telefone2,
                whats: 'N' , //whats ? 'S' : 'N',
                email,
                cep,
                logradouro,
                complemento,
                bairro,
                cidade,
                uf,
                opcao_pelo_simples : simples,
                opcao_pelo_mei: mei,
                matriz_filial: matrizFilial,
                situacao_cadastral: situacaoCadastral,
                data_inicio_atividade: dtAbertura,
                inscricao_municipal: inscricaoMunicipal,
                inscricao_suframa: inscricaoSuframa,
                status : 'Ativo',
                contatos_cliente: contatos
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 201)
                    toast.success('Cliente cadastrado com sucesso');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
    }   

    function validaCPF(strCPF) {
        let Soma;
        let Resto;
        let i;
        Soma = 0;
      if (strCPF === "00000000000") return false;
    
      for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false;
    
      Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return false;
        return true;
    }

    function validarCNPJ(cnpj) {
 
        if (!cnpj) return false

        // Aceita receber o valor como string, número ou array com todos os dígitos
        const validTypes =
          typeof cnpj === 'string' || Number.isInteger(cnpj) || Array.isArray(cnpj)
      
        // Elimina valor em formato inválido
        if (!validTypes) return false
      
        // Guarda um array com todos os dígitos do valor
        const match = cnpj.toString().match(/\d/g)
        const numbers = Array.isArray(match) ? match.map(Number) : []
      
        // Valida a quantidade de dígitos
        if (numbers.length !== 14) return false
        
        // Elimina inválidos com todos os dígitos iguais
        const items = [...new Set(numbers)]
        if (items.length === 1) return false
      
        // Cálculo validador
        const calc = (x) => {
          const slice = numbers.slice(0, x)
          let factor = x - 7
          let sum = 0
      
          for (let i = x; i >= 1; i--) {
            const n = slice[x - i]
            sum += n * factor--
            if (factor < 2) factor = 9
          }
      
          const result = 11 - (sum % 11)
      
          return result > 9 ? 0 : result
        }
      
        // Separa os 2 últimos dígitos de verificadores
        const digits = numbers.slice(12)
        
        // Valida 1o. dígito verificador
        const digit0 = calc(12)
        if (digit0 !== digits[0]) return false
      
        // Valida 2o. dígito verificador
        const digit1 = calc(13)
        return digit1 === digits[1]
            
      
    }

    function onHideEdit(){
        // setClienteId('')
        setVisibleEdit(false)
    }

    function handleEditItem(data){
        
        let contato = contatos.filter((contato) => contato.id === data.id)

        setNewId(contato[0].id)
        setNewContato(contato[0].contato)
        setNewEmail(contato[0].email)
        setNewTelefone1(contato[0].telefone1)
        setNewWhats1(contato[0].whats1)
        setNewTelefone2(contato[0].telefone2)
        setNewWhats2(contato[0].whats2)
        setNewCargo(contato[0].cargo)
        setNewStatus(contato[0].status)

        setVisibleEdit(true)
    }
        
    function handleAddNewContact(event, action){
        const PrevContatos = contatos.filter((contato) => contato.id !== newId)
        const EditContato = contatos.filter((contato) => contato.id === newId)

        const contato ={
            'id': action === 'new' ? Date.now() : newId,
            'contato': newContato, 
            'email': newEmail, 
            'telefone1': newTelefone1, 
            'whats1': newWhats1, 
            'telefone2': newTelefone2, 
            'whats2': newWhats2, 
            'cargo': newCargo, 
            'status': action === 'new' ? 'Ativo' : newStatus, 
            'action': EditContato[0]?.action || action
        }
        setNewId('')
        setNewContato('')
        setNewEmail('')
        setNewTelefone1('')
        setNewWhats1('')
        setNewTelefone2('')
        setNewWhats2('')
        setNewCargo('')
        setNewStatus('')

        setContatos(PrevContatos.concat(contato))
    }

    const actionBodyTemplate = (data) => {
    return (
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-success " onClick={(e) => handleEditItem(data)} />
        </div>
    );
    }        

    return <>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Cadastro de Cliente"}>
            {/* <NavLink to="/users/novo" className="btn btn-primary mb-2"><MdPersonAdd fontSize="24px" /> Novo Usuário</NavLink> */}
        </Title>

        <Sidebar visible={visibleEdit} style={{width:'100%', maxWidth: '400px', zIndex:100}} position="right" onHide={() => onHideEdit()}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <InputText
                        title={"Nome Contato"}
                        name={"pessoaContato"}
                        type={"text"}
                        value={newContato}
                        setData={event => setNewContato(event.target.value?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {return a.toUpperCase();}))}
                    /> 
                </div>    
                <div className="p-field p-col-12">
                    <InputText
                        title={"Email"}
                        name={"emailContato"}
                        type={"email"}
                        value={newEmail}
                        setData={event => setNewEmail(event.target.value?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {return a.toUpperCase();}))}
                    /> 
                </div>                                                              
                <div className="p-field p-col-12">
                    <InputMask
                        title={"Telefone 1"}
                        name={"telefone1"}
                        value={newTelefone1}
                        setData={event => setNewTelefone1(event.target.value)}
                        mask={"(99) 9 9999-999?9"}
                    /> 
                </div>                                                              
                <div className="p-field p-col-12">
                <InputMask
                        title={"Telefone 2"}
                        name={"telefone2"}
                        value={newTelefone2}
                        setData={event => setNewTelefone2(event.target.value)}
                        mask={"(99) 9 9999-999?9"}
                    /> 
                </div>                                                              
                <div className="p-field p-col-12">
                    <InputText
                        title={"Cargo"}
                        name={"cargo"}
                        type={"text"}
                        value={newCargo}
                        setData={event => setNewCargo(event.target.value?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {return a.toUpperCase();}))}
                    /> 
                </div>      
                {newId === '' ? '' :
                    <div className="p-field p-col-12">
                        <SelectPrime 
                            title={'Status'} 
                            name={'status'} 
                            data={dataStatus}
                            value={newStatus}
                            setData={event => setNewStatus(event.value)}
                        />
                    </div>     
                }
                <div className="p-field p-col-12">
                    <div className="p-d-flex p-jc-evenly">
                        {newId=== ''?
                            <Button icon="pi pi-plus" className="p-button-sm p-button-rounded p-button-primary" onClick={(event) =>handleAddNewContact(event, 'new')} />                                                                                   
                        :
                        <>
                            <Button icon="pi pi-pencil" className="p-button-sm p-button-rounded p-button-primary" onClick={(event) =>handleAddNewContact(event, null)} />                                                                                   
                            <Button icon="pi pi-trash" className="p-button-sm p-button-rounded p-button-danger" onClick={(event) =>handleAddNewContact(event, '*')} />                                                                                   
                        </>
                        }
                    </div>     
                </div>     
            </div>
        </Sidebar>

        <Card width="100%">
            <Container>                
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-sm-6">
                    <SelectPrime 
                        title={'Tipo Pessoa'} 
                        name={'tipoPessoa'} 
                        data={dataTipoPessoa}
                        value={tipoPessoa}
                        setData={event => setTipoPessoa(event.value)}
                        focus={true}    
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    {tipoPessoa === 'CPF'?
                        <InputMask
                            title={"CPF/CNPJ"}
                            type={"text"}
                            name={"cfp"}
                            value={cpf}
                            setData={(event) => setCpf(event.target.value)}
                            mask={"999.999.999-99"}
                        />
                    :
                        <InputMask
                            title={"CPF/CNPJ"}
                            type={"text"}
                            name={"Cnpj"}
                            value={cnpj}
                            // focus={true}
                            setData={(event) => setCnpj(event.target.value)}
                            mask={"99.999.999/9999-99"}
                            buttonFunction={tipoPessoa === 'CNPJ' ? (event) => handleGetCNPJ(): false}
                        />
                    }
                </div>

                <div className="p-field p-col-12">
                    <InputText
                        title={'Fantasia'} 
                        name="fantasia" 
                        value={fantasia}
                        setData={event => setFantasia(event.target.value)}
                    />
                </div> 


                <div className="p-field p-col-12">
                    <Accordion className="accordion-custom" activeIndex={0}>
                        <AccordionTab header={`Informações Fiscais`}>
                            <div className='p-fluid p-formgrid p-grid'>
                                <div className="p-field p-col-12">
                                    <InputText
                                        title={'Razão Social'} 
                                        name="razaoSocial" 
                                        value={razaoSocial}
                                        setData={event => setRazaoSocial(event.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputText
                                        title={'Inscrição Estadual'} 
                                        name="inscricaoEstadual" 
                                        value={inscricaoEstadual}
                                        setData={event => setInscricaoEstadual(event.target.value)}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputText
                                        title={'Inscrição Municipal'} 
                                        name="inscricaoMunicipal" 
                                        value={inscricaoMunicipal}
                                        setData={event => setInscricaoMunicipal(event.target.value)}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputText
                                        title={'Inscrição Suframa'} 
                                        name="inscricaoSuframa" 
                                        value={inscricaoSuframa}
                                        setData={event => setInscricaoSuframa(event.target.value)}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputText
                                        title={'CNAE'} 
                                        name="cnae" 
                                        value={cnae}
                                        setData={event => setCNAE(event.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='p-fluid p-formgrid p-grid'>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <SelectPrime 
                                        title={'Optante pelo Simples'} 
                                        name={'optanteSimpes'} 
                                        data={dataYesNo}
                                        value={simples}
                                        setData={event => setSimples(event.value)}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <SelectPrime 
                                        title={'Optante pelo MEI'} 
                                        name={'optanteSimpes'} 
                                        data={dataYesNo}
                                        value={mei}
                                        setData={event => setMei(event.value)}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <SelectPrime 
                                        title={'Matriz/Filial'} 
                                        name={'matrizFilial'} 
                                        data={dataMatrizFilial}
                                        value={matrizFilial}
                                        setData={event => setMatrizFilial(event.value)}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <SelectPrime 
                                        title={'Situação Cadastral'} 
                                        name={'situacaoCadastral'} 
                                        data={dataStatus}
                                        value={situacaoCadastral}
                                        setData={event => setSituacaoCadastral(event.value)}
                                    />
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header={`Informações Adicionais`}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputText
                                        title={'Email'} 
                                        name="email" 
                                        value={email}
                                        type={email}
                                        setData={event => setEmail(event.target.value)}
                                    /> 
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputMask
                                        title={"Telefone 1"}
                                        name={"telefone1"}
                                        value={telefone1}
                                        setData={event => setTelefone1(event.target.value)}
                                        mask={"(99) 99999-999?9"}
                                    />  
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputMask
                                        title={"Telefone 2"}
                                        name={"telefone2"}
                                        value={telefone2}
                                        setData={event => setTelefone2(event.target.value)}
                                        mask={"(99) 99999-999?9"}
                                    />  
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-md-3">
                                    <InputText
                                        title={"Abertura Empresa"}
                                        name={"aberturaEmpresa"}
                                        type={"date"}
                                        value={dtAbertura}
                                        setData={event => setDtAbertura(event.target.value)}
                                    />    
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header={`Endereço`}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-sm-6 p-md-2">
                                    <InputMask
                                        title={"CEP"}
                                        name={"cep"}
                                        value={cep}
                                        setData={event => setCep(event.target.value)}
                                        buttonFunction={event => handleGetEndereco()}
                                        mask={"99999-999"}
                                    /> 
                                </div>    
                                <div className="p-field p-col-12 p-sm-6 p-md-5">
                                    <InputText
                                        title={"Logradouro"}
                                        name={"logradouro"}
                                        type={"text"}
                                        value={logradouro}
                                        setData={event => setLogradouro(event.target.value)}
                                    /> 
                                </div>                                                              
                                <div className="p-field p-col-12 p-sm-6 p-md-5">
                                    <InputText
                                        title={"Complemento"}
                                        name={"complemento"}
                                        type={"text"}
                                        value={complemento}
                                        setData={event => setComplemento(event.target.value)}
                                    /> 
                                </div>                                                              
                                <div className="p-field p-col-12 p-sm-6 p-md-5">
                                    <InputText
                                        title={"Bairro"}
                                        name={"bairro"}
                                        type={"text"}
                                        value={bairro}
                                        setData={event => setBairro(event.target.value)}
                                    /> 
                                </div>                                                              
                                <div className="p-field p-col-12 p-sm-6 p-md-5">
                                    <InputText
                                        title={"Cidade"}
                                        name={"cidade"}
                                        type={"text"}
                                        value={cidade}
                                        setData={event => setCidade(event.target.value)}
                                    /> 
                                </div>                                                              
                                <div className="p-field p-col-12 p-sm-6 p-md-2">
                                    <InputText
                                        title={"UF"}
                                        name={"uf"}
                                        type={"text"}
                                        value={uf}
                                        setData={event => setUF(event.target.value)}
                                    /> 
                                </div>                                
                            </div>
                        </AccordionTab>

                        <AccordionTab header={`Outros Contatos`}>
                            <Table data={contatos.filter((contato) => contato.action !== '*')} header={false} paginator={false} className={false}>
                                <Column field={'contato'}        header={'Nome'} sortable filter={false}/>
                                <Column field={'email'}        header={'E-mail'} sortable filter={false}/>
                                <Column field={'telefone1'}        header={'Telefone 1'} style={{width:'15.5%'}} sortable filter={false}/>
                                <Column field={'telefone2'}        header={'Telefone 2'} style={{width:'15.5%'}} sortable filter={false}/>
                                <Column field={'cargo'}        header={'Cargo'} sortable filter={false}/>
                                <Column field={'status'} header={'Status'} body={statusTemplate} style={{width:'12%'}} sortable filter={false}/>
                                <Column body={actionBodyTemplate} style={{width:'7%'}} header={<Button icon="pi pi-plus" className="p-button-sm p-button-rounded p-button-primary" onClick={(e) => setVisibleEdit(true)} />} ></Column>
                                {/* <Column body={actionBodyTemplate} style={{width:'7%'}} ></Column> */}
                            </Table>
                        </AccordionTab>
                    </Accordion>
                </div>
                                                                         
            </div>

            <ButtonPrime onClick={handleCreateCliente} text="Salvar" icon="save"/>
            
            </Container>
        </Card>
            </>
}

export default CreateCliente;