import React from 'react';
import { Button } from 'primereact/button';

import { Container } from './styles';

const Input : React.FC = ({ onClick, text, icon, disabled, colorBg}) => {
    return (
        <>  
       <Container colorBg={colorBg}>
       <div className="button-demo">
            <div className="template">
                <Button className="discord p-p-0" onClick={onClick} disabled={disabled}>
                    <i className={`pi pi-${icon} p-px-2`}></i>
                    <span className="p-px-3">{text}</span>
                </Button>
            </div>
        </div>
        </Container> 
        </>
    )
}

export default Input