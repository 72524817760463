import React, { useState, useEffect } from 'react';

import {toast} from 'react-toastify'

import api from '../../services/api';
import Loading from '../../components/loading'

import './index.css'

// import logo from '../../assets/logo.png';
import logoExectuApp from '../../assets/logoExecutApp.svg';
import '../../assets/logoExecutApp.png';

import InputPassword from '../../components/form/primeReact/inputPassword'
import InputText from '../../components/form/primeReact/inputText'

export default function Login({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState('')
  useEffect(()=>{
    function Logout(){
      // localStorage.removeItem("user");
      sessionStorage.removeItem("token");
    }

    function initLogin(){
      setLogo(JSON.parse(localStorage?.getItem('tenant'))?.tenant_settings[0].url_logo)
      // console.log(JSON.parse(localStorage.getItem('tenent')).url_logo)
    }

    Logout();
    initLogin()
  }, [])

 
  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    await api.post('/sessions', {
      email, password
    })
    .then(response => { 
      // console.log(response)
      localStorage.removeItem("user");
      localStorage.removeItem("tenent");

      console.log(response.data)

      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      localStorage.setItem("tenant", JSON.stringify(response?.data?.empresa[0]));
      sessionStorage.setItem('token', response?.data?.token);
      // console.log(response)
      history.push('/Dashboard');
    })
    .catch(error => {
      if ((error.response?.data.error)){
        toast.error(error.response?.data.error)
      }else{
        toast.error(error.message)
      }
    });

    setLoading(false);
  }

  return (
    <>
      <Loading loading={loading} message='Entrando...' />
      <div className="containerbody">

      {/* <video id="video_rand" autoPlay="autoplay" muted="muted" loop="loop" className="video">
        <source src="/assets/movie/back-login-3.mp4" type="video/mp4" className="video_source"/>
      </video> */}

        <div className="content">
          <div className="logo">
            <img  src={logo || `https://s3.us-east-2.amazonaws.com/files.executapp.com/logoExecutApp.png`} alt="Logo" />
          </div>
          <form onSubmit={handleSubmit}>


            <label htmlFor="email">E-mail</label>
            <InputText type="email"
              id="email"
              className="form-control"
              setData={event => setEmail(event.target.value)}
              autoComplete="off"
              focus={true}
            />

            <label htmlFor="password">Senha</label>
            <InputPassword type="password"
              feedback={false}
              id="password"
              className="form-control"
              placeholder="Password"
              setData={event => setPassword(event.target.value)}
            />

            <button type="submit" className="btn btn-primary">Entrar</button>
            <a href='/forgot-password'>Esqueci a senha</a>
          </form>
        </div>
          <div className="logoExecutApp">
            <img  src={logoExectuApp} alt="LogoExecutApp" />
          </div>
      </div>
    </>
  );
}