import styled from 'styled-components';

export const Cards = styled.div`
    display: flex;
    flex-direction: row;

    margin: 0 0 .3rem 0;

    width: 100%;

    justify-content: space-around;

    .cards-corpo{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1% 3%;
        font-weight: bold;
    }

    .cards-graf{
        width: 30%;
    }

    .cards-corpo .cards-title{
        // color: #999;
        font-size: 2.3rem;
    }
    .cards-corpo span{
        color: #999;
        font-size: 1.7rem;
    }
`;