import React from 'react';
import { FiAlertCircle } from 'react-icons/fi'



import { Container, ContainerInput, Headerinput, InputPass, Title, Error } from './styles';

const Input : React.FC = ({
    name, type, placeholder, title, register, errors, setData, disabled, value, focus,feedback
}) => {
    return (
        <>  
            <Container>
                {title && 
                <Headerinput>
                    <Title>{title}:</Title>
                </Headerinput>
                }
                <ContainerInput>
                    <InputPass 
                        id="in" 
                        name={name}  
                        value={value} 
                        onChange={setData} 
                        autoFocus={focus} 
                        feedback={feedback} 
                        toggleMask 
                        disabled={disabled} 
                    />
                    {/* <input 
                        className="form-control form-control-lg" 
                        name={name} 
                        type={type} 
                        ref={register} 
                        placeholder={placeholder} 
                        onChange={setData} 
                        disabled={disabled} 
                        autoFocus={focus} 
                        value={value} 
                        step="any"
                    /> */}
                    {errors && <FiAlertCircle size={20} />}
                </ContainerInput>
                <Error>{errors?.message}</Error>
            </Container>
        </>
    )
}

export default Input