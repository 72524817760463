import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Layout from '../../../components/layout'
import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Table from '../../../components/table'

import Card from '../../../components/card'

import ButtonPrime from '../../../components/form/primeReact/button'

import PermissionRole from '../../../components/permissionRole'

import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';

import {idTemplate, tituloTemplate, clienteOrcamentoTemplate, condPagtoNomeTemplate, formaPagtoTemplate, garantiaTemplate, statusChamadoTemplate, validadeOrcamentoTemplate, valorTotalTemplate} from '../../../components/table/service'
import { Button } from 'primereact/button';

import EditOrcamento  from '../Editar'
import NovoOrcamento  from '../Novo'

function Orcamentos() {
    const [orcamentos, setOrcamentos] = useState([]);
    const [loading, setLoading] = useState(false);

    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);

    const [userId, setUserId] = useState('')
    const [idOrcamento, setIdOrcamento] = useState('')

    // const {id, perfil} = JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        async function getOrcamentos() {
            setLoading(true);

            const token = sessionStorage.getItem('token');
                await api.get('/orcamentos', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setOrcamentos(response.data.result);
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        getOrcamentos();
    }, [userId, visibleCreate, visibleEdit])

    function onVisibleEdit(uuid){
        setIdOrcamento(uuid)
        setVisibleEdit(true)
    }
    function onHideEdit(){
        setUserId('')
        setVisibleEdit(false)
    }

    function onVisibleCreate(){
        setVisibleCreate(true)
    }
    function onHidecreate(){
        setUserId('')
        setVisibleCreate(false)
    }
    function abrePDF(url_pdf){
        window.open(url_pdf);
    }

    const actionBodyTemplate = (data) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <Button icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-success" onClick={(e) => onVisibleEdit(data.uuid, data.cliente_id)}/>
                {/* <a href={`${data.url_pdf}`} target = "_blank"><Button icon="pi pi-file-pdf" className={`p-button-sm p-button-rounded p-button-${data.url_pdf ? 'warning' : 'secondary'} p-mr-2`} disabled={true}/></a> */}
                {data.url_pdf ?
                <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-success" onClick={(e) => abrePDF(data.url_pdf)} />
                :
                <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-secondary p-button-outlined button-bloq" disabled/>
                }
            </div>
        );
    }    

    return <Layout>
        <Loading loading={loading} message='Carregando...' />


        <Title title={`Orçamentos`} >
            <PermissionRole Role={'Supervisor,Administrador'}>
                <ButtonPrime icon={'cog'} text={"Novo Orçamento"} onClick={(e) => onVisibleCreate()}/>
            </PermissionRole>
        </Title>
        
        <Sidebar visible={visibleEdit} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHideEdit()}>
            <EditOrcamento idOrcamento={`${idOrcamento}`}  />
        </Sidebar>
        <Sidebar visible={visibleCreate} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHidecreate()}>
            <NovoOrcamento />
        </Sidebar>
        
        <Card width="100%" >
            <Table data={orcamentos} sortField="id" sortOrder={-1}>
                <Column field={'id'}        header={'ID'}               body={idTemplate}       style={{width:'3.5rem', maxWidth:'100%'}} sortable filter={false} />
                <Column field={'titulo'}      header={'TÍTULO'}             body={tituloTemplate}  style={{width:'auto', maxWidth:'100%'}}   sortable filter={false}></Column>
                <Column field={'cliente_orcamento.fantasia'}     header={'CLIENTE'}           body={clienteOrcamentoTemplate}    sortable filter={false}></Column>
                <Column field={'cond_pagto'}     header={'CONDIÇÃO PAGAMENTO'}           body={condPagtoNomeTemplate}    sortable filter={false}></Column>
                <Column field={'forma_pagto'}     header={'FORMA PAGAMENTO'}           body={formaPagtoTemplate}    sortable filter={false}></Column>
                <Column field={'periodo_garantia'} header={'GARANTIA'}     body={garantiaTemplate}  sortable filter={false}></Column>
                <Column field={'validade'} header={'VALIDADE ORÇAMENTO'} body={validadeOrcamentoTemplate}  sortable filter={false}></Column>
                <Column field={'valor_total'}     header={'VALOR'}           body={valorTotalTemplate}    sortable filter={false}></Column>
                <Column field={'status'}    header={'STATUS'}           body={statusChamadoTemplate}   sortable filter={false}></Column>
                {/* <Column field={'fim_atendimento'} header={'FIM ATENDIMENTO'} body={fimAtendimentoTemplate}  sortable filter={false}></Column> */}
                <Column body={actionBodyTemplate} style={{width:'6.5rem'}} ></Column>
            </Table>
        </Card>
    </Layout>
}

export default Orcamentos;
