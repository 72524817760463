import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => {    

    return (
        <>
        <Container>
            <span>
                © 2022 - ExecutApp
            </span>
            <span>
                {/* <a href="https://executsystem.com.br" rel="noopener noreferrer" target = "_ blank" >https://executsystem.com.br</a> */}
            </span>
        </Container>
        </>
    );
}

export default Footer;