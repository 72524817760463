import React from 'react';
import { isAuthenticated } from './auth';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from  './pages/Login2';
import ForgotPassword from  './pages/Login2/forgotPassword';
import ResetPassword from  './pages/Login2/resetPassword';

import NpsResposta from  './pages/Login2/npsResposta';

import Dashboard from  './pages/Dashboard';

import Users from  './pages/Users/Index';
// import NovoUser from  './pages/Users/Novo';
// import EditarUser from  './pages/Users/Editar';

import Clientes from  './pages/Clientes/Index';
import Tenants from  './pages/Tenants/Index';
import Equipamentos from  './pages/Equipamentos/Index';
import Chamados from  './pages/Chamados/Index';
import Orcamentos from  './pages/Orcamentos/Index';


import Error404 from './pages/Error404'


const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Component {...props} />
        ):(
            <Redirect to={{pathname: '/', state: { from: props.location}}} />
        )
    )} />
    )


export default function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/"  component={Login} />
                <Route exact path="/forgot-Password"  component={ForgotPassword} />
                <Route exact path="/reset-Password"  component={ResetPassword} />

                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                
                <PrivateRoute exact path="/users" component={Users} />
                {/* <PrivateRoute exact path="/users/novo" component={NovoUser} />
                <PrivateRoute exact path="/users/edit/:idUser" component={EditarUser} /> */}
                
                <PrivateRoute exact path="/tenants" component={Tenants} />

                <PrivateRoute exact path="/clientes" component={Clientes} />
                <PrivateRoute exact path="/cliente/:idCliente/equipamentos" component={Equipamentos} />
                
                <PrivateRoute exact path="/ordens-servicos" component={Chamados} />
                
                <PrivateRoute exact path="/orcamentos" component={Orcamentos} />

                <Route exact path="/answers/:nota"  component={NpsResposta} />

                <Route component={Error404} />
            </Switch>
        </BrowserRouter>
    );
}
