import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import SelectPrime from '../../../components/form/primeReact/select'
import InputText from '../../../components/form/primeReact/inputText'
import InputPassword from '../../../components/form/primeReact/inputPassword'
import ButtonPrime from '../../../components/form/primeReact/button'

import { Container } from './styles'

function NovoUser() {
    const [loading, setLoading] = useState(false)

    const [users, setUsers] = useState([]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [perfil, setPerfil] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [senha, setSenha] = useState('');

    const dataPerfil = [{value: 'Operador', label: 'Operador'},{value: 'Supervisor', label: 'Supervisor'},{value: 'Administrador', label: 'Administrador'}]    

    const {perfil : perfilLogged} = JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        async function handle_getUsers(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get('/users', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setUsers(response.data.users
                        .filter(user => user.perfil === 'Supervisor' && user.status==="Ativo")
                        .map(user => {
                        return {'value': user.id, 'label': user.name.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        }) + ' - ' + user.perfil}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        handle_getUsers()
    }, [])

    function handleNovoUsuario(event) {
        event.preventDefault()
        setLoading(true)
        const token = sessionStorage.getItem('token');
        try {
            api.post('/users',
            {
                name,
                email,
                perfil, 
                supervisor,
                status: 'Ativo',
                password: senha
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.id)
                    toast.success('Usuário cadastrado com sucesso');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }

      }

      useEffect(()=>{
          function handle_reseta_sup(){
              if(perfil !== "Operador") setSupervisor('')
        }

        handle_reseta_sup()
      },[perfil])

    return <>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Cadastro de Usuário"}>
            {/* <NavLink to="/users/novo" className="btn btn-primary mb-2"><MdPersonAdd fontSize="24px" /> Novo Usuário</NavLink> */}
        </Title>

        <Card width="100%">
            <Container>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <InputText
                        title={'Nome'} 
                        name="name" 
                        value={name}
                        setData={event => setName(event.target.value)}
                        focus={true}
                    />
                </div>
                <div className="p-field p-col-12">
                    <InputText
                        title={'E-mail'} 
                        name="email" 
                        value={email}
                        setData={event => setEmail(event.target.value)}
                    />
                </div>

                <div className="p-field p-col">
                    <SelectPrime 
                        title={'Perfil'} 
                        name={'perfil'} 
                        data={perfilLogged === "Administrador" ? dataPerfil : dataPerfil.filter(data => data.value === 'Operador')}
                        value={perfil}
                        setData={event => setPerfil(event.value)}
                    />
                </div>

                <div className="p-field p-col">
                    <SelectPrime 
                        title={'Supervisor'} 
                        name={'supervisor'} 
                        data={users}
                        value={supervisor}
                        disabled={perfil==='Operador'? false : true}
                        setData={event => setSupervisor(event.value)}
                    />
                </div>

                <div className="p-field p-col-12">
                    <InputPassword
                        title={'Senha'} 
                        name="password"
                        value={senha}
                        setData={event => setSenha(event.target.value)}
                    />
                </div>
            </div>
            
            <ButtonPrime onClick={handleNovoUsuario} text="Salvar" icon="save"/>

            </Container>
        </Card>


    </>
}

export default NovoUser;