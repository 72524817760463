import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

export const ContainerTextArea = styled.div`
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    position: relative;
    /* margin-bottom: 1%; */
    
    textarea{
        flex:1;
        width:100%;
        display: flex;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ced4da;
        border-radius: 5px;
        padding: 5px;
        /* color: var(--primary); */
        
        ::placeholder{
            color: #ffffff45;
        }
    }


    svg{
        color: var(--ingeconBordo);
    }
`; 

export const HeaderTextArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
`;

export const Title = styled.span`
    color: var(--secondary);
    font-weight: 700;
    display: block;
`;

export const CountCaractere = styled.span`
    font-size: 12px;
`

export const Error = styled.span`
    color: var(--ingeconBordo);
    font-weight: 500;
    width: 100%;
    display: block;
    flex: 1;
    text-align: right;
    font-size: 12px;
`; 