import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import { PrimeIcons } from 'primereact/api';

import Layout from '../../../components/layout'
import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Table from '../../../components/table'

import Card from '../../../components/card'

import ButtonPrime from '../../../components/form/primeReact/button'

import PermissionRole from '../../../components/permissionRole'

import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';

import {idTemplate, nameTemplate, emailTemplate, supervisorTemplate, perfilTemplate, statusTemplate, createdTemplate, updatedTemplate} from '../../../components/table/service'
import { Button } from 'primereact/button';

import EditUser  from '../Editar'
import NovoUser  from '../Novo'

function Users() {
    const [users, setUser] = useState([]);
    const [loading, setLoading] = useState(false);

    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);

    const [userId, setUserId] = useState('')

    const {id, perfil} = JSON.parse(localStorage.getItem('user') || '');


    useEffect(() => {
        async function getUsers() {
            setLoading(true);

            const token = sessionStorage.getItem('token');
                await api.get('/users', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => { 
                    setLoading(false)
                    if (perfil === 'Operador'){
                        setUser(response.data?.users?.filter(user => user.id === id));
                    }
                    if (perfil === 'Supervisor'){
                        setUser(response.data?.users?.filter(user => user.supervisor === id || user.id === id));
                    }
                    if (perfil === 'Administrador' || perfil === 'Super'){
                        setUser(response.data?.users);
                    }
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        getUsers();
    }, [userId, perfil])

    function onVisibleEdit(uuid){
        setUserId(uuid)
        setVisibleEdit(true)
    }
    function onHideEdit(){
        setUserId('')
        setVisibleEdit(false)
    }

    function onVisibleCreate(){
        setVisibleCreate(true)
    }
    function onHidecreate(){
        setUserId('')
        setVisibleCreate(false)
    }

    const actionBodyTemplate = (data) => {
        return (
            // <i className="pi pi-ellipsis-v mr-2" onClick={(e) => onVisibleEdit(data.id)} />
            // <Button icon="pi pi-ellipsis-v" onClick={(e) => onVisibleEdit(data.id)}/>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <Button icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-success p-button-outlined"  onClick={(e) => onVisibleEdit(data.uuid)}/>
                {/* <NavLink to={`/users/edit/${data.id}`} ><Button icon="pi pi-trash" className="p-button-sm p-button-rounded p-button-warning p-mr-2"/></NavLink> */}
            </div>
        );
    }    

    return <Layout>
        <Loading loading={loading} message='Carregando...' />


        <Title title={"Usuários"} >
            <PermissionRole Role={'Supervisor,Administrador,super'}>
                <ButtonPrime icon={'user-plus'} text={"Novo Usuário"} onClick={(e) => onVisibleCreate()}/>
            </PermissionRole>
        </Title>
        
        <Sidebar visible={visibleEdit} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHideEdit()}>
            <EditUser uuid={`${userId}`} />
        </Sidebar>
        <Sidebar visible={visibleCreate} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHidecreate()}>
            <NovoUser />
        </Sidebar>
        
        <Card width="100%">
            <Table data={users} >
                <Column field={'id'}        header={'ID'}               body={idTemplate}       style={{width:'70px'}} sortable filter={false} width="70px" />
                <Column field={'name'}      header={'NOME'}             body={nameTemplate}     sortable filter={false}></Column>
                <Column field={'email'}     header={'E-MAIL'}           body={emailTemplate}    sortable filter={false}></Column>
                <Column field={'user_supervisor.name'}    header={'SUPERVISOR'}           body={supervisorTemplate}   sortable filter={false}></Column>
                <Column field={'perfil'}    header={'PERFIL'}           body={perfilTemplate}   sortable filter={false}></Column>
                <Column field={'status'}    header={'STATUS'}           body={statusTemplate}   style={{width:'100px'}} sortable filter={false}></Column>
                <Column field={'createdAt'} header={'DATA CRIAÇÃO'}     body={createdTemplate}  sortable filter={false}></Column>
                <Column field={'updatedAt'} header={'DATA ATUALIZAÇÃO'} body={updatedTemplate}  sortable filter={false}></Column>
                <Column body={actionBodyTemplate} style={{width:'7%'}} ></Column>
            </Table>
        </Card>
    </Layout>
}

export default Users;