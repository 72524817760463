import React from 'react';

import { ToastContainer } from 'react-toastify';

const Toastify = () => { 
    return ( 
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={true}
            draggable
            pauseOnHover
        />
    ) 
} 
 
export default Toastify;