import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Layout from '../../../components/layout'
import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Table from '../../../components/table'

import Card from '../../../components/card'

import ButtonPrime from '../../../components/form/primeReact/button'

import PermissionRole from '../../../components/permissionRole'

import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';
import { Menu } from 'primereact/menu';

import {idTemplate, razao_social, fantasia, cidade, uf, statusTemplate, createdTemplate, updatedTemplate} from '../../../components/table/service'
import { Button } from 'primereact/button';

import EditCliente  from '../Editar'
import NovoCliente  from '../Novo'

function Clientes() {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(false);

    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);

    const [clienteId, setClienteId] = useState('')

    const [itensMenu, setItensMenu] = useState([])
    // const {id, perfil} = JSON.parse(localStorage.getItem('user') || '');


    useEffect(() => {
        async function getClientes() {
            setLoading(true);

            const token = sessionStorage.getItem('token');
                await api.get('/clientes', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setClientes(response.data?.result);
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        getClientes();
    }, [clienteId])

    function onVisibleEdit(uuid){
        setClienteId(uuid)
        setVisibleEdit(true)
    }
    function onHideEdit(){
        setClienteId('')
        setVisibleEdit(false)
    }

    function onVisibleCreate(){
        setVisibleCreate(true)
        setClienteId('*')
    }
    function onHidecreate(){
        setClienteId('')
        setVisibleCreate(false)
    }

    const menu = useRef(null);
    
    const actionBodyTemplate = (data) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <Button icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-primary p-mr-2" onClick={(e) => onVisibleEdit(data.uuid)}/>
                <NavLink to={`/cliente/${data.uuid}/equipamentos`} ><Button icon="pi pi-cog" className="p-button-sm p-button-rounded p-button-secondary p-mr-2"/></NavLink>
            </div>
            )
        }    
        
    return <Layout>
        <Loading loading={loading} message='Carregando...' />
        <Menu model={itensMenu} popup ref={menu} id="popup_menu" />

        <Title title={"Clientes"} >
            <PermissionRole Role={'Supervisor,Administrador'}>
                <ButtonPrime icon={'user-plus'} text={"Novo Cliente"} onClick={(e) => onVisibleCreate()}/>
            </PermissionRole>
        </Title>
        
        <Sidebar visible={visibleEdit} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHideEdit()}>
            <EditCliente uuid={`${clienteId}`} />
        </Sidebar>
        <Sidebar visible={visibleCreate} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHidecreate()}>
            <NovoCliente />
        </Sidebar>
        
        <Card width="100%">
            <Table data={clientes} >
                <Column field={'id'}        header={'ID'}               body={idTemplate}       style={{width:'70px'}} sortable filter={false} width="70px" />
                <Column field={'razao_social'}      header={'RAZÃO SOCIAL'}             body={razao_social}     sortable filter={false}></Column>
                <Column field={'fantasia'}          header={'FANTASIA'}                 body={fantasia}     sortable filter={false}></Column>
                <Column field={'cidade'}            header={'CIDADE'}                   body={cidade}     sortable filter={false}></Column>
                <Column field={'uf'}                header={'UF'}                       body={uf}     sortable filter={false}></Column>

                <Column field={'status'}    header={'STATUS'}           body={statusTemplate}   style={{width:'100px'}} sortable filter={false}></Column>
                <Column field={'createdAt'} header={'DATA CRIAÇÃO'}     body={createdTemplate}  sortable filter={false}></Column>
                <Column field={'updatedAt'} header={'DATA ATUALIZAÇÃO'} body={updatedTemplate}  sortable filter={false}></Column>
                <Column body={actionBodyTemplate} style={{width:'auto'}} ></Column>
            </Table>
        </Card>
    </Layout>
}

export default Clientes;