import styled from 'styled-components';

export const Container = styled.div`
.button-demo .p-button {
    margin-right: 0.5rem;
}
.button-demo .p-buttonset .p-button {
    margin-right: 0;
}
.button-demo .template .p-button i {
    line-height: 2.7rem;
    font-size: 1.3rem;
}
.button-demo .template .p-button.discord {
    background: linear-gradient(to left, var(--${props => props.colorBg || 'blue'}-400) 50%, var(--${props => props.colorBg || 'blue'}-500) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--${props => props.colorBg || 'blue'}-500);
    line-height: 2.7rem;
    font-size: 1.1rem;
}
.button-demo .template .p-button.discord:hover {
    background-position: left bottom;
}
.button-demo .template .p-button.discord i {
    background-color: var(--${props => props.colorBg || 'blue'}-500);
}
.button-demo .template .p-button.discord:focus {
    box-shadow: 0 0 0 1px var(--${props => props.colorBg || 'blue'}-200);
}
@media screen and (max-width: 960px) {
    .button-demo .p-button {
        margin-bottom: 0.5rem;
    }
    .button-demo .p-button:not(.p-button-icon-only) {
        display: flex;
        width: 100%;
    }
    .button-demo .p-buttonset .p-button {
        margin-bottom: 0;
    }
}
`;