import React, { useState, useEffect } from 'react';

import api from '../../services/api';
import Loading from '../../components/loading'

import './index.css'

// import logo from '../../assets/logo.png';
import logoExectuApp from '../../assets/logoExecutApp.svg';

export default function Login({ history }) {
  const [resposta, setResposta] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(()=>{

    async function NPSresponse(){
      await api.get(`${window.location.pathname}${window.location.search}`)
      .then(response => { 
        if (response.data.message) {
          setResposta(response.data.message)
          
        }else {
          setResposta(response.data.error)
  
        }
      })
      .catch(error => {
        if ((error.response?.data.error)){
          setResposta(error.response?.data.error)
        }else{
          setResposta(error.message)
        }
      })
      .finally(()=> {
        setLoading(false)
     });

    }

    NPSresponse()
  }, [])

    return (
    <>
      <Loading loading={loading} message='Entrando...' />
      <div className="containerbody">

      {/* <video id="video_rand" autoPlay="autoplay" muted="muted" loop="loop" className="video">
        <source src="/assets/movie/back-login-3.mp4" type="video/mp4" className="video_source"/>
      </video> */}

        <div className="content">
          <div className="logo">
            <img  src={JSON.parse(localStorage?.getItem('tenent'))?.url_logo || `http://files.executapp.com/logoExecutApp.png`} alt="Logo" />
          </div>
          <form >

            <p>
              {resposta}
            </p>

          </form>
        </div>
          <div className="logoExecutApp">
            <img  src={logoExectuApp} alt="LogoExecutApp" />
          </div>
      </div>
    </>
  );
}