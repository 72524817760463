import React from 'react';
import { FiAlertCircle } from 'react-icons/fi'

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Container, ContainerInput, Headerinput, Title, Error } from './styles';

const Input : React.FC = ({
    name, type, placeholder, title, register, errors, setData, disabled, value, focus, buttonFunction, buttonIcon
}) => {
    return (
        <>  
            <Container>
                {title && 
                <Headerinput>
                    <Title>{title}:</Title>
                </Headerinput>
                }
                <ContainerInput>
                <div className="p-inputgroup">
                        <InputText 
                                type={type}  
                                name={name}  
                                value={value} 
                                placeholder={placeholder} 
                                onChange={setData} 
                                autoFocus={focus} 
                                disabled={disabled}
                            />
                        {buttonFunction? 
                            <Button 
                                icon={`pi pi-${buttonIcon || 'search'}`} 
                                className="p-button-primary"
                                onClick={buttonFunction}
                                disabled={disabled}
                            />
                        : ""
                        }
                    </div>
                    {/* <input 
                        className="form-control form-control-lg" 
                        name={name} 
                        type={type} 
                        ref={register} 
                        placeholder={placeholder} 
                        onChange={setData} 
                        disabled={disabled} 
                        autoFocus={focus} 
                        value={value} 
                        step="any"
                    /> */}
                    {errors && <FiAlertCircle size={20} />}
                </ContainerInput>
                <Error>{errors?.message}</Error>
            </Container>
        </>
    )
}

export default Input