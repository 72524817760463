import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


import { toast } from 'react-toastify';

import api from '../../../services/api'

import Layout from '../../../components/layout'
import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Table from '../../../components/table'

import Card from '../../../components/card'

import ButtonPrime from '../../../components/form/primeReact/button'

import PermissionRole from '../../../components/permissionRole'

import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';

import {idTemplate, nameTemplate, fabricanteTemplate, modeloTemplate, serieTemplate, responsavelTemplate, statusTemplate, createdTemplate, updatedTemplate} from '../../../components/table/service'
import { Button } from 'primereact/button';

import EditEquipamento  from '../Editar'
import NovoEquipamento  from '../Novo'

function ClienteEquipamento() {
    const [equipamentos, setEquipamentos] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [loading, setLoading] = useState(false);

    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);

    const [userId, setUserId] = useState('')

    // const {id, perfil} = JSON.parse(localStorage.getItem('user') || '');

    const { idCliente } = useParams('');
    const [idEquipamento, setIdEquipamento]= useState('')

    useEffect(() => {
        async function handle_getClientes(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${idCliente}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    if(response?.data?.code === '404'){
                        toast.error(response?.data?.error)
                    }else{
                        setCliente(response?.data?.result);
                    }
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        handle_getClientes();
    }, [idCliente])

    useEffect(() => {
        async function getEquipamento() {
            setLoading(true);

            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${idCliente}/equipamento`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setEquipamentos(response.data.result);
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        if (idCliente)
            getEquipamento();
    }, [userId, idCliente])

    function onVisibleEdit(uuid, cliente_id){
        setUserId(uuid)
        setIdEquipamento(uuid)
        setVisibleEdit(true)
    }
    function onHideEdit(){
        setUserId('')
        setVisibleEdit(false)
    }

    function onVisibleCreate(){
        setUserId(idCliente)
        setVisibleCreate(true)
    }
    function onHidecreate(){
        setUserId('')
        setVisibleCreate(false)
    }

    const actionBodyTemplate = (data) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <Button icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-success"  onClick={(e) => onVisibleEdit(data.uuid, data.cliente_id)}/>
                {/* <NavLink to={`/users/edit/${data.id}`} ><Button icon="pi pi-trash" className="p-button-sm p-button-rounded p-button-warning p-mr-2"/></NavLink> */}
            </div>
        );
    }    

    return <Layout>
        <Loading loading={loading} message='Carregando...' />


        <Title title={`Equipamentos ${cliente?.fantasia? ' - ' + cliente?.fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                                return a.toUpperCase();
                                            }) : ''}`} >
            <PermissionRole Role={'Supervisor,Administrador'}>
                <ButtonPrime icon={'cog'} text={"Novo Equipamento"} onClick={(e) => onVisibleCreate()}/>
            </PermissionRole>
        </Title>
        
        <Sidebar visible={visibleEdit} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHideEdit()}>
            <EditEquipamento idCliente={`${idCliente}`} idEquipamento={`${idEquipamento}`}  />
        </Sidebar>
        <Sidebar visible={visibleCreate} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHidecreate()}>
            <NovoEquipamento idCliente={`${idCliente}`}/>
        </Sidebar>
        
        <Card width="100%">
            <Table data={equipamentos} >
                <Column field={'id'}        header={'ID'}               body={idTemplate}       style={{width:'70px'}} sortable filter={false} width="70px" />
                <Column field={'name'}      header={'NOME'}             body={nameTemplate}     sortable filter={false}></Column>
                <Column field={'fabricantes'}     header={'FABRICANTE'}           body={fabricanteTemplate}    sortable filter={false}></Column>
                <Column field={'modelo'}     header={'MODELO'}           body={modeloTemplate}    sortable filter={false}></Column>
                <Column field={'serie'}     header={'SÉRIE'}           body={serieTemplate}    sortable filter={false}></Column>
                <Column field={'responsavel_tecnico'}     header={'RESPONSÁVEL'}           body={responsavelTemplate}    sortable filter={false}></Column>
                <Column field={'status'}    header={'STATUS'}           body={statusTemplate}   style={{width:'100px'}} sortable filter={false}></Column>
                <Column field={'createdAt'} header={'DATA CRIAÇÃO'}     body={createdTemplate}  sortable filter={false}></Column>
                <Column field={'updatedAt'} header={'DATA ATUALIZAÇÃO'} body={updatedTemplate}  sortable filter={false}></Column>
                <Column body={actionBodyTemplate} style={{width:'7%'}} ></Column>
            </Table>
        </Card>
    </Layout>
}

export default ClienteEquipamento;