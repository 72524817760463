import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Layout from '../../../components/layout'
import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Table from '../../../components/table'

import Card from '../../../components/card'

import ButtonPrime from '../../../components/form/primeReact/button'

import PermissionRole from '../../../components/permissionRole'

import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';

import {idTemplate, tituloOcorrenciaTemplate, clienteFantasiaTemplate, equipamentoChamadoNomeTemplate, tipoManutencaoTemplate, tecnicoChamadoNomeTemplate, statusChamadoTemplate, createdTemplate, inicioAtendimentoTemplate, fimAtendimentoTemplate} from '../../../components/table/service'
import { Button } from 'primereact/button';

import EditEquipamento  from '../Editar'
import NovoEquipamento  from '../Novo'

function ClienteEquipamento() {
    const [chamados, setChamados] = useState([]);
    const [loading, setLoading] = useState(false);

    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);

    const [userId, setUserId] = useState('')
    const [idChamado, setIdChamado] = useState('')

    // const {id, perfil} = JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        async function getChamados() {
            setLoading(true);

            const token = sessionStorage.getItem('token');
                await api.get('/chamados', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setChamados(response.data.result);
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        getChamados();
    }, [userId])

    function onVisibleEdit(id){
        setIdChamado(id)
        setUserId(id)
        setVisibleEdit(true)
    }
    function onHideEdit(){
        setUserId('')
        setVisibleEdit(false)
    }

    function onVisibleCreate(){
        setUserId(1)
        setVisibleCreate(true)
    }
    function onHidecreate(){
        setUserId('')
        setVisibleCreate(false)
    }
    function abrePDF(url_pdf){
        window.open(url_pdf);
    }

    const actionBodyTemplate = (data) => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <Button icon="pi pi-eye" className="p-button-sm p-button-rounded p-button-success" onClick={(e) => onVisibleEdit(data.uuid, data.cliente_id)}/>
                {/* <a href={`${data.url_pdf}`} target = "_blank"><Button icon="pi pi-file-pdf" className={`p-button-sm p-button-rounded p-button-${data.url_pdf ? 'warning' : 'secondary'} p-mr-2`} disabled={true}/></a> */}
                {data.url_pdf ?
                <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-success" onClick={(e) => abrePDF(data.url_pdf)} />
                :
                <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-secondary p-button-outlined button-bloq" disabled/>
                }
            </div >
        );
    }    

    return <Layout>
        <Loading loading={loading} message='Carregando...' />


        <Title title={`Ordens de Serviço`} >
            <PermissionRole Role={'Supervisor,Administrador'}>
                <ButtonPrime icon={'cog'} text={"Nova ordem de serviço"} onClick={(e) => onVisibleCreate()}/>
            </PermissionRole>
        </Title>
        
        <Sidebar visible={visibleEdit} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHideEdit()}>
            <EditEquipamento idChamado={`${idChamado}`}  />
        </Sidebar>
        <Sidebar visible={visibleCreate} style={{width:'100%', maxWidth: '800px', zIndex:100}} position="right" onHide={() => onHidecreate()}>
            <NovoEquipamento />
        </Sidebar>
        
        <Card width="100%">
            <Table data={chamados} sortField="id" sortOrder={-1}>
                <Column field={'id'}        header={'ID'}               body={idTemplate}       style={{width:'3.5rem'}} sortable filter={false} width="70px" />
                <Column field={'titulo_ocorrencia'}      header={'TÍTULO'}             body={tituloOcorrenciaTemplate}     sortable filter={false}></Column>
                <Column field={'cliente_chamado.fantasia'}     header={'CLIENTE'}           body={clienteFantasiaTemplate}    sortable filter={false}></Column>
                <Column field={'equipamento_chamado.nome'}     header={'EQUIPAMENTO'}           body={equipamentoChamadoNomeTemplate}    sortable filter={false}></Column>
                <Column field={'tipo_manutencao'}     header={'TIPO MANUTENÇÃO'}           body={tipoManutencaoTemplate}    sortable filter={false}></Column>
                <Column field={'createdAt'} header={'DATA CRIAÇÃO'}     body={createdTemplate}  sortable filter={false}></Column>
                <Column field={'status'}    header={'STATUS'}           body={statusChamadoTemplate}   sortable filter={false}></Column>
                <Column field={'tecnico_chamado.name'}     header={'TÉCNICO'}           body={tecnicoChamadoNomeTemplate}    sortable filter={false}></Column>
                <Column field={'inicio_atendimento'} header={'INICIO ATENDIMENTO'} body={inicioAtendimentoTemplate}  sortable filter={false}></Column>
                <Column field={'fim_atendimento'} header={'FIM ATENDIMENTO'} body={fimAtendimentoTemplate}  sortable filter={false}></Column>
                <Column body={actionBodyTemplate} style={{width:'7%'}} ></Column>
            </Table>
        </Card>
    </Layout>
}

export default ClienteEquipamento;