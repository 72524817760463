import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px;
    width: 100%;
    height: 50px;
    flex-direction: row;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-bottom: 1%;
`;

export const Name = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;

    h3{
        font-size: 1.75rem;
        font-weight: 500;
    }

    h6{
        margin-left: 5px;
        color: #adb5bd;
        font-size: 1.3rem;

    }

    span{
        color: #adb5bd;
        font-size: 1.3rem;

        &:hover {
            /* color: #000; */
            font-size: 1.75rem;
            font-style: italic;
            font-weight: bold;
        }
    }
`
