import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import InputText from '../../../components/form/primeReact/inputText'
import TextAreaPrime from '../../../components/form/primeReact/textArea'
import SelectPrime from '../../../components/form/primeReact/select'
import ButtonPrime from '../../../components/form/primeReact/button'

import { Container } from './styles'

const NovoEquipamento : React.FC = ({ idCliente }) =>{
    const [loading, setLoading] = useState(false)
    
    const [users, setUsers] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);

    const [tituloOcorrencia, setTituloOcorrencia] = useState('')
    const [localOcorrencia, setLocalOcorrencia] = useState('');
    const [equipamento, setEquipamento] = useState('')
    const [situacaoEquipamento, setSituacaoEquipamento] = useState('')
    const [tipoManutencao, setTipoManutencao]= useState('')
    const [tipoServico, setTipoServico]= useState('')
    const [descricaoProblema, setDescricaoProblema] = useState('');

    const [prioridade, setPrioridade] = useState('');
    const [dtAgendamento, setDtAgendamento] = useState('');
    const [hrAgendamento, setHrAgendamento] = useState('');
    const [tecnico, setTecnico] = useState('');

    const dataSituacao = [{value: 'Erro Continuo', label: 'Erro Continuo'},{value: 'Parada', label: 'Parada'},{value: 'Operacional', label: 'Operacional'}]
    const dataTipoManut = [{value: 'Corretiva', label: 'Corretiva'},{value: 'Preventiva', label: 'Preventiva'},{value: 'Preditiva', label: 'Preditiva'},{value: 'Melhoria', label: 'Melhoria'},{value: 'Implantação', label: 'Implantação'},{value: 'Desinstalação', label: 'Desinstalação'},{value: 'Treinamento', label: 'Treinamento'}]
    const dataTipoServ = [{value: 'Elétrica / Eletrônica', label: 'Elétrica / Eletrônica'},{value: 'Civil / Predial', label: 'Civil / Predial'},{value: 'Mecânica', label: 'Mecânica'}]
    const dataPrioridade = [{value: 'Critica', label: 'Critica'}, {value: 'Muito Alta', label: 'Muito Alta'}, {value: 'Alta', label: 'Alta'}, {value: 'Média', label: 'Média'}, {value: 'Baixa', label: 'Baixa'}, {value: 'Muito Baixa', label: 'Muito Baixa'}]

    useEffect(() => {
        async function handle_getEquipamentos(){
            setLoading(true)
            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${localOcorrencia}/equipamento`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setEquipamentos(response.data.result.map(cliente => {
                        return {value: cliente.uuid, label: `${cliente.nome.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        })} ${cliente.modelo ? ' - ' + cliente.modelo.toUpperCase() : ''}`}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        if (localOcorrencia)
            handle_getEquipamentos();
    }, [localOcorrencia])


    useEffect(() => {
        async function handle_getUsers(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get('/users', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setUsers(response.data.users.map(user => {
                        return {'value': user.uuid, 'label': user.name.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        })}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        async function handle_getClientes(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get('/clientes', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setLoading(false) 
                    setClientes(response.data.result.map(cliente => {
                        return {'value': cliente.uuid, 'label': cliente.fantasia?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                            return a.toUpperCase();
                        })}
                    }));
                })
                .catch(error => {
                    setLoading(false)
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }

        handle_getUsers();
        handle_getClientes();
    }, [])

    function handleSalvarChamado(event){
        event.preventDefault()
        setLoading(true)

        const token = sessionStorage.getItem('token');
        try {
            api.post('/chamados',
            {
                titulo_ocorrencia: tituloOcorrencia,
                local_ocorrencia: localOcorrencia,
                equipamento,
                situacao_equipamento: situacaoEquipamento,
                tipo_manutencao: tipoManutencao,
                tipo_servico: tipoServico,
                descricao_ocorrencia: descricaoProblema,

                prioridade,
                data_agendamento: dtAgendamento,
                hora_agendamento: hrAgendamento,
                uuid_tecnico: tecnico
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 201)
                    toast.success('Ordem de serviço aberta com sucesso');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
    }

    return <>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Nova Ordem de Serviço"}>
            {/* <NavLink to="/users/novo" className="btn btn-primary mb-2"><MdPersonAdd fontSize="24px" /> Novo Usuário</NavLink> */}
        </Title>
        <Card width="100%">
            <Container>                
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">

                <InputText
                        title={"Título Ocorrência"}
                        name={"tituloOcorrencia"}
                        type={"text"}
                        value={tituloOcorrencia}
                        setData={event => setTituloOcorrencia(event.target.value)}
                        focus={true}
                    />
                </div>
            
                <div className="p-field p-col-12 p-sm-6">
                <SelectPrime 
                        title={'Cliente Ocorrência'} 
                        name={'tituloOcorrencia'} 
                        data={clientes} 
                        value={localOcorrencia}
                        setData={event => setLocalOcorrencia(event.value)}
                    />
                </div>       
                <div className="p-field p-col-12 p-sm-6">
                    <SelectPrime 
                        title={"Equipamento"}
                        name={"equipamentos"}
                        data={equipamentos} 
                        value={equipamento}
                        setData={event => setEquipamento(event.value)}
                    />
                </div>                           

                <div className="p-field p-col-12 p-sm-6 p-md-4">
                    <SelectPrime 
                        title={"Situação Equipamento"}
                        name={"situacaoEquipamento"}
                        data={dataSituacao} 
                        value={situacaoEquipamento}
                        setData={event => setSituacaoEquipamento(event.value)}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-4">
                    <SelectPrime 
                        title={"Tipo Manutenção"}
                        name={"tipoManutencao"}
                        data={dataTipoManut} 
                        value={tipoManutencao}
                        setData={event => setTipoManutencao(event.value)}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-4">
                    <SelectPrime 
                        title={"Tipo Serviço"}
                        name={"tipoServico"}
                        data={dataTipoServ} 
                        value={tipoServico}
                        setData={event => setTipoServico(event.value)}
                    />
                </div> 

                <div className="p-field p-col-12">
                    <TextAreaPrime
                        title={"Descrição Problema"} 
                        name={'descricaoProblema'} 
                        rows={4} 
                        value={descricaoProblema}
                        setData={event => setDescricaoProblema(event.target.value)}
                    />  
                </div> 

                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <SelectPrime 
                        title={"Técnico"}
                        name={"tecnico"}
                        data={users.filter(user => (user.label?.toLowerCase().split(' - ')[0] !== 'suporte'))}  
                        value={tecnico}
                        setData={event => setTecnico(event.value)}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <SelectPrime 
                        title={"Prioridade"}
                        name={"urgencia"}
                        data={dataPrioridade} 
                        value={prioridade}
                        setData={event => setPrioridade(event.value)}
                    />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <InputText
                        title="Data Agendamento"
                        type="date"
                        value= {dtAgendamento}
                        setData= {event => setDtAgendamento(event.target.value)} 
                        />
                </div> 
                <div className="p-field p-col-12 p-sm-6 p-md-3">
                    <InputText
                        title="Hora Agendamento"
                        type="time" 
                        value= {hrAgendamento}
                        setData= {event => setHrAgendamento(event.target.value)} 
                    />
                </div> 
            </div>

            <ButtonPrime onClick={handleSalvarChamado} text="Salvar" icon="save"/>
              
            </Container>
        </Card>
            </>
}

export default NovoEquipamento;