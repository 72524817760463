import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    /* background-image: linear-gradient(to bottom right, var(--secondary), var(--primary)); */
    flex-direction: row;
    background-color: var(--footer);
    color: #C3C0B6;
    font-size: 12px;

    a{
        color: #C3C0B6;
    }
    &:hover{
        color: #fff
    }
`;