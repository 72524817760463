import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *, label{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        outline: 0px;
    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
        height: 1%;
        /* border-radius: 10px; */
    }
    ::-webkit-scrollbar
    {
        width: 5px;
        height: 1%;
        background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb
    {
        background-image: linear-gradient(to bottom right, var(--secondary), var(--primary));
        /* border-radius: 5px; */
        /* background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.1, #00C8F8),color-stop(0.6, #8DBCF7)); */
        /* background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.44, rgb(122,153,217)),color-stop(0.72, rgb(73,125,189)),color-stop(0.86, #314755)); */
    }
    //css react table
    //css react table
    }
    a{
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }
    
    html, body, #root{
        max-height: 100vh;
        max-width: 100vw;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #ecf0f5;
        font-size: 12px;
        @media(max-width: 960px) {
            .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
                text-align: left;
            }
        }
        @media(min-width: 1000px) {
            font-size: 13px;
        }
        @media(min-width: 1200px) {
            font-size: 14px;
        }
        @media(min-width: 1900px) {
            font-size: 16px;
        }
    }
    *, input, button, select, textarea{
        /* -moz-appearance: none;
        -webkit-appearance: none;
        appearance: chekbox; */
        border: 0;
        background: none;
        outline:0 ;
        font-family: "Open Sans",sans-serif;
    }
    svg.CircularProgressbar {
        heigth: 100%;
    }
    // canvas{
    //     height: 100%;
    //     width: 100%;
    // }
    .datatable-filter-demo .p-datatable .p-datatable-customers .p-paginator{
        padding: 0.3rem ;
    }    
    .datatable-filter-demo .p-datatable .p-datatable-customers .p-datatable-thead > tr > th {
        text-align: center;
        white-space: pre-wrap;
        VERTICAL-ALIGN: middle;
    }
    .datatable-filter-demo .p-datatable .p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: center;
        white-space: pre-wrap;
        VERTICAL-ALIGN: middle;
        padding: .2rem;
    }
   .button-bloq{
        cursor: not-allowed !important;
        pointer-events: painted;
   }

    :root{
        /* --primary: #8DBCF7;
        --secondary: #00C8F8; */
        --primary: #7FE0FF;
        --secondary: #1B3A66;
        --footer: #3F6F7E;
        --white: #fff;
        --grey: #d2d6de;
    }
    /* BOOTSTRAP */
        .row{
            width: 100%;
        }
        
        .btn50{
            display: flex;
            align-items: flex-end;
            button{
                height: calc(1.5em + .4rem + 2px);
                /* padding: 0.5rem 1rem; */
                font-size: 1.25rem;
                line-height: 1.5;
                border-radius: 0.3rem;
            }
        }
    /* BOOTSTRAP */
    
    .p-disabled, .p-component:disabled{
        background-color: #e9ecef;
        opacity: 1;
    }
    .p-grid{
        width: 100%;
    }
    .customer-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.customer-badge .status-qualified {
    background-color: #C8E6C9;
    color: #256029;
}
.customer-badge.status-Inativo, .customer-badge.status-vencido {
    background-color: #FFCDD2;
    color: #C63737;
}
.customer-badge.status-Ativo {
    background: #C8E6C9;
    color: #256029;
}
.customer-badge.status-finalizado, .customer-badge.status-aprovado {
    background: #28a745;
    color: #fff;
}
.customer-badge.status-iniciado, .customer-badge.status-reprovado {
    background: #F28C37;
    color: #fff;
}
.customer-badge.status-direcionado, .customer-badge.status-aguardando{
    background: #40BCD8;
    color: #fff;

}
.customer-badge.status-pendente {
    background: #99582A;
    color: #fff;
}
.customer-badge.status-cancelado {
    background: #999;
    color: #fff;
}
.customer-badge.tipo-material {
    background: #AD3C0E;
    color: #fff;
}
.customer-badge.tipo-serviço {
    background: #CA5310;
    color: #fff;
}

.displayFlex{
    display: flex;
    // flex: 1;
    width: 100%;
    
    align-items:center;
    justify-content: left;

    // margin-bottom: 10px;

    label{
        margin-left: 0.5rem;
    }
}


// .p-accordion .p-accordion-tab {
//     margin-bottom: 4px;
// }
// .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
//     background: #f8f9fa;
//     border-color: #dee2e6;
//     color: #343a40;
//     border-bottom-right-radius: 0;
//     border-bottom-left-radius: 0;
// }


// .p-accordion .p-accordion-header .p-accordion-header-link {
//     transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
// }

// .p-accordion .p-accordion-header .p-accordion-header-link {
//     padding: 1.25rem;
//     border: 1px solid #dee2e6;
//     color: #6c757d;
//     background: #f8f9fa;
//     font-weight: 700;
//     border-radius: 6px;
//     transition: box-shadow 0.2s;
// }

// .p-accordion-header-link {
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     user-select: none;
//     position: relative;
//     text-decoration: none;
// }

// .p-component, .p-component * {
//     box-sizing: border-box;
// }

// .p-toggleable-content-enter-done {
//     transform: none;
// }

// .p-accordion .p-accordion-content {
//     padding: 1.25rem;
//     border: 1px solid #dee2e6;
//     background: #ffffff;
//     color: #495057;
//     border-top: 0;
//     border-top-right-radius: 0;
//     border-top-left-radius: 0;
//     border-bottom-right-radius: 6px;
//     border-bottom-left-radius: 6px;
// }
`;