import React from 'react';

import { Container } from './styles';

const Card: React.FC = ({width, minWidth, height, children}) => {
    return (
        <>
            <Container 
                width={width} 
                minWidth={minWidth} 
                height={height}>
                {children}
            </Container>
        </>
    );
}

export default Card;