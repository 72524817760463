import styled from 'styled-components';

export const Container = styled.div`
    position: relative;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  
`

export const CondPagtoStyle = styled.div`
    display: flex;
    // flex: 1;
    width: 100%;
    
    align-items:center;
    justify-content: left;

    // margin-bottom: 10px;

    label{
        margin-left: 0.5rem;
    }

    
`
