import React, {useEffect, useState} from 'react';

import api from '../../services/api'

const PermissionRole: React.FC = ({Role, children}) => {    
    const [hasPermission, setHasPermission] = useState(false);

    const {uuid} = JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        async function handle_getUser(){
            // setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get(`/users/${uuid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                // console.log(uuid)    

                    setHasPermission(Role.split(",").some(r =>
                        r.includes(response.data?.user?.perfil)))

                    // setHasPermission(response.data?.user?.perfil === Role ? true : false);
                })
                .catch(error => {});
            // setLoading(false)
        }

        handle_getUser();
    }, [Role, uuid])

    return (
        <>
           
                {hasPermission && children}

        </>
    );
}

export default PermissionRole;