import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { PanelMenu } from 'primereact/panelmenu';

import { FaUsers, RiDashboardLine, IoMdConstruct } from 'react-icons/all'

import { Container, Logo, Menu, SidebarLink, InfoUser, Profile, Loggoff, Title, UserName } from './styles';

// import logo from '../../assets/logo.png';

import PermissionRole from '../permissionRole'

const Sidebar: React.FC = () => {    
    const [user, setUser] = useState('');
    const [perfil, setPerfil] = useState('');
    const [logo, ] = useState(JSON.parse(localStorage?.getItem('tenant'))?.tenant_settings[0].url_logo)

    // console.log(JSON.parse(localStorage?.getItem('tenant'))?.tenant_settings[0].url_logo)

    useEffect(() => {
        const userLoged = JSON.parse(localStorage.getItem('user') || '');
        setUser(userLoged.name);
        setPerfil(userLoged.perfil);
    }, [])    

    const items = [
        {
            label:'Cadastros',
            items:[
                {
                    label:'Bookmark',
                    icon:'pi pi-fw pi-bookmark',
                    template: (item, options) => {
                        return(
                            <SidebarLink to="/tenants">Empresas</SidebarLink>

                        )

                    }
                },
            ]
        },
        {
            label:'Edit',
            icon:'pi pi-fw pi-pencil',
            items:[
                {
                    label:'Left',
                    icon:'pi pi-fw pi-align-left',
                    command: (event) => {
                        window.location.href = "/tenants";
                    }
                },
                {
                    label:'Right',
                    icon:'pi pi-fw pi-align-right'
                },
                {
                    label:'Center',
                    icon:'pi pi-fw pi-align-center'
                },
                {
                    label:'Justify',
                    icon:'pi pi-fw pi-align-justify'
                }
            ]
        },
    ];

    return (
        <>
        <Container>
        <div className="logo">
           <a href='/dashboard'><Logo src={logo || `http://files.executapp.com/logoExecutApp.png`} /></a>
        </div>
            <Menu>
                <nav className="primnav" id="tree">

                    <PermissionRole Role={'Super Adm'}>
                        <ul>                      
                            <li className="group">
                                <a href="#Administracao">
                                    <RiDashboardLine size={25} />
                                    <span>Configurações</span>
                                    <div className="tag"></div>
                                </a>
                                <ul className="secnav">
                                    <li>
                                    <SidebarLink to="/tenants">Empresas</SidebarLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </PermissionRole>

                    <ul>                      
                        <li className="group">
                            <a href="#users">
                                <FaUsers size={25} />
                                <span>Cadastros</span>
                                <div className="tag"></div>
                            </a>
                            <ul className="secnav">
                                <li>
                                <SidebarLink to="/users">Usuários</SidebarLink>
                                </li>
                            </ul>
                            <ul className="secnav">
                                <li>
                                <SidebarLink to="/clientes">Clientes</SidebarLink>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <ul>                      
                        <li className="group">
                            <a href="#chamados">
                                <IoMdConstruct size={25} />
                                <span>Serviços</span>
                                <div className="tag"></div>
                            </a>
                            <ul className="secnav">
                                <li>
                                <SidebarLink to="/orcamentos">Orçamentos</SidebarLink>
                                </li>
                                <li>
                                <SidebarLink to="/ordens-servicos">Ordens de Serviço</SidebarLink>
                                </li>
                            </ul>
                        </li>
                    </ul>

            {/* <PanelMenu model={items} style={{ width: '100%' }}/> */}
                </nav>
            </Menu>


            <InfoUser >
                <NavLink to="/">
                    <Loggoff />
                </NavLink>
                <Profile>
                    <UserName>
                            <strong>{user?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}</strong>
                    </UserName>
                    <Title>
                    {perfil?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                            return a.toUpperCase();
                                        })}
                    </Title>
                </Profile>
            </InfoUser>
        </Container>
        </>
    );
}

export default Sidebar;