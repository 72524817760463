import styled from 'styled-components';

import {Password} from 'primereact/password';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

export const ContainerInput = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    

    svg{
        color: var(--ingeconBordo);
    }
`; 

export const InputPass = styled(Password)`
    width: 100%;

    input {
        width: 100%;
    }
`

export const Headerinput = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
`;

export const Title = styled.span`
    color: var(--secondary);
    font-weight: 700;
    display: block;
`;


export const Error = styled.span`
    color: var(--ingeconBordo);
    font-weight: 500;
    width: 100%;
    display: block;
    flex: 1;
    text-align: right;
    font-size: 12px;
`; 