import React from 'react';
import { FiAlertCircle } from 'react-icons/fi'

import { InputTextarea } from 'primereact/inputtextarea';

import { Container, ContainerTextArea, HeaderTextArea, Title, Error } from './styles';

const Input : React.FC = ({
    name, title, register, errors, disabled, value, rows, cols, setData
}) => {
    // console.log(value);
    return (
        <>  
            <Container>
                <HeaderTextArea>
                    <Title>{title}:</Title>
                    {/* <CountCaractere>{value ? value.length : ''}</CountCaractere> */}
                </HeaderTextArea>
                <ContainerTextArea>
                    <InputTextarea className="form-control form-control-lg"  disabled={disabled} value={value ?  value : ''} name={name} rows={rows} cols={cols} onChange={setData}/>
                    {errors && <FiAlertCircle size={20} />}
                </ContainerTextArea>
                    <Error>{errors?.message}</Error>
            </Container>
        </>
    )
}

export default Input