import styled from 'styled-components';



export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #111;

    .text{
        position: relative;
        transform: skewY(345deg) translateY(-200px);
        animation: animateColor 5s linear infinite;
    }

    @keyframes animateColor{
        0%{
            filter: hue-rotate(0deg);
        }
        100%{
            filter: hue-rotate(360deg);
        }
    }

    .text h2{
        position: relative;
        width: 100%;
        font-size: 6em;
        color: #fff;
        pointer-events: none;
        line-height: 1em;
        white-space: nowrap;
        text-shadow: calc(var(--x)) 400px 0 rgba(255, 255, 255, .1);
        transform: translateX(calc(0% - var(--x) * 1 ));
    }
    @keyframes randomGen {
    100% { transform: translateX(-300 / $charNum * 100%); }
    }
    .text h2 span{
        color: #0f0;
        margin: 0 10px;
    }

    .text h2 span:nth-child(even){
        color: transparent;
        -webkit-text-stroke: 2px #fff;
    }
`

