import React, { useEffect, useState }  from 'react';
import { CircularProgressbarWithChildren, buildStyles  } from 'react-circular-progressbar'

import Loading from '../../components/loading'
import Layout from '../../components/layout';
import Title from '../../components/title'
import Card from '../../components/card'
import Doughnut from '../../components/graficos/Doughnut'
import HorizontalBar from '../../components/graficos/HorizontalBar'
import Table from '../../components/table'

import { Column } from 'primereact/column';
import {idTemplate, tituloOcorrenciaTemplate, clienteFantasiaTemplate, equipamentoChamadoNomeTemplate, tipoManutencaoTemplate, tecnicoChamadoNomeTemplate, statusChamadoTemplate, createdTemplate, inicioAtendimentoTemplate} from '../../components/table/service'

import { toast } from 'react-toastify';

import api from '../../services/api'

import {Cards} from './styles'

export default function Dashboard() {
    const [loading, setLoading] = useState(false);   
    
    const [chamados, setChamados] = useState([])

    const [chamadosTotal, setChamadosTotal] = useState('')
    const [direcionados, setDirecionados] = useState('')
    const [iniciados, setIniciados] = useState('')
    const [finalizados, setFinalizados] = useState('')

    const [grafTipoManutencao, setGrafTipoManutencao]= useState([])
    const [grafTipoServico, setGrafTipoServico]= useState([])
    const [grafTecnico, setGrafTecnico]= useState([])

    useEffect(() => {
        async function getInfosDash() {
        setLoading(true);

        const token = sessionStorage.getItem('token');

            await api.get('/dashboard', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                
                setChamadosTotal(response?.data?.filter(chamado => chamado.key === 'abertura' && chamado.label === 'criados').reduce((resultado, chamado) => {return (resultado + chamado.real );}, ''))
                setDirecionados(response?.data?.filter(chamado => chamado.key === 'status' && chamado.label === 'direcionado').reduce((resultado, chamado) => {return (resultado + chamado.real );}, ''))
                setIniciados(response?.data?.filter(chamado => chamado.key === 'status' && chamado.label === 'iniciado').reduce((resultado, chamado) => {return (resultado + chamado.real );}, ''))
                setFinalizados(response?.data?.filter(chamado => chamado.key === 'status' && chamado.label === 'finalizado').reduce((resultado, chamado) => {return (resultado + chamado.real );}, ''))

                let TipoManutLabel=[], TipoManutData=[]
                setGrafTipoManutencao(response?.data?.filter(chamado => chamado.key === 'tipo manutencao').reduce((resultado, chamado) => {
                    TipoManutLabel.push(chamado.label)
                    TipoManutData.push(chamado.real)
                    // console.log(label)
                    return {
                        'label': TipoManutLabel,
                        'data': TipoManutData
                    }
                    ;}
                , 0))
        
                let TipoServLabel=[], TipoServData=[]
                setGrafTipoServico(response?.data?.filter(chamado => chamado.key === 'tipo servico').reduce((resultado, chamado) => {
                    TipoServLabel.push(chamado.label)
                    TipoServData.push(chamado.real)
                    // console.log(label)
                    return {
                        'label': TipoServLabel,
                        'data': TipoServData
                    }
                    ;}
                , 0))
        
                let TipoTecnicoLabel=[], TipoTecnicoData=[]
                setGrafTecnico(response?.data?.filter(chamado => chamado.key === 'tecnico atribuido').reduce((resultado, chamado) => {
                    TipoTecnicoLabel.push(chamado.label)
                    TipoTecnicoData.push(chamado.real)
                    // console.log(label)
                    return {
                        'label': TipoTecnicoLabel,
                        'data': TipoTecnicoData
                    }
                    ;}
                , 0))
            })
            .catch(error => {
                if ((error.response?.data.error)){
                    toast.error(error.response?.data.error)
                }else{
                    toast.error(error.message)
                }
            }).finally(()=> {
                setLoading(false)
            });
        }

        async function getChamados() {
            setLoading(true);

            const token = sessionStorage.getItem('token');
                await api.get('/chamados', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setChamados(response.data.result.filter(chamado => chamado.status !== 'finalizado'))
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }        

        getInfosDash(); 
        getChamados();       
    }, [])

    
    return <Layout>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Dashboard"} />
        <Cards >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-m-0 p-py-1 p-px-1 p-col-12 p-md-6 p-lg-4">
                    <Card >
                        <div>
                            <h3>Ordens Direcionadas</h3>
                        </div>

                        <div className='cards-corpo'>
                            <div className='cards-title'>
                                {direcionados? direcionados : 0}<span> / {chamadosTotal? chamadosTotal : 0}</span>
                            </div>

                            <div className='cards-graf'>
                                <CircularProgressbarWithChildren value={direcionados/chamadosTotal*100} strokeWidth={10}
                                    styles={buildStyles({
                                        pathColor: `#40BCD8`,
                                        strokeLinecap: 'round',
                                        trailColor: '#F1F3F5'
                                    })} >
                                        {chamadosTotal? (direcionados/chamadosTotal*100).toFixed(2) : 0.00}%
                                </CircularProgressbarWithChildren>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="p-field p-m-0 p-py-1 p-px-1 p-col-12 p-md-6 p-lg-4">
                    <Card >
                        <div>
                            <h3>Ordens Iniciadas</h3>
                        </div>
                        
                        <div className='cards-corpo'>
                            <div className='cards-title'>
                                {iniciados? iniciados : 0}<span> / {chamadosTotal? chamadosTotal : 0}</span>
                            </div>

                            <div className='cards-graf'>
                                <CircularProgressbarWithChildren value={iniciados/chamadosTotal*100} strokeWidth={10}
                                    styles={buildStyles({
                                        pathColor: `#F28C37`,
                                        strokeLinecap: 'round',
                                        trailColor: '#F1F3F5'
                                    })} >
                                        {chamadosTotal? (iniciados/chamadosTotal*100).toFixed(2) : 0.00}%
                                </CircularProgressbarWithChildren>
                            </div>
                        </div>

                    </Card>
                </div>     
                <div className="p-field p-m-0 p-py-1 p-px-1 p-col-12 p-md-6 p-lg-4">
                    <Card >
                        <div>
                            <h3>Ordens Finalizadas</h3>
                        </div>
                        <div className='cards-corpo'>
                            <div className='cards-title'>
                                {finalizados? finalizados : 0}<span> / {chamadosTotal? chamadosTotal : 0}</span>
                            </div>

                            <div className='cards-graf'>
                                <CircularProgressbarWithChildren value={finalizados/chamadosTotal*100} strokeWidth={10}
                                    styles={buildStyles({
                                        pathColor: `#28a745`,
                                        strokeLinecap: 'round',
                                        trailColor: '#F1F3F5'
                                    })} >
                                        {chamadosTotal? (finalizados/chamadosTotal*100).toFixed(2) : 0.00}%
                                </CircularProgressbarWithChildren>
                            </div>
                        </div>
                    </Card>
                </div>     
            </div>
        </Cards>

        <Cards>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-m-0 p-py-1 p-px-1 p-col-12 p-md-6 p-lg-4">
                    <Card>
                        <div>
                            <h3>Tipos Manutenção</h3>
                        </div>
                        <div style={{width:100+'%', height: 95+'%'}}>
                            <Doughnut labels={grafTipoManutencao.label} data={grafTipoManutencao.data}/>
                        </div>
                    </Card>
                </div>
                <div className="p-field p-m-0 p-py-1 p-px-1 p-col-12 p-md-6 p-lg-4">
                    <Card>
                        <div>
                            <h3>Tipos Serviço</h3>
                        </div>
                        <div style={{width:100+'%', height: 95+'%'}}>
                            <Doughnut labels={grafTipoServico.label} data={grafTipoServico.data}/>
                        </div>
                    </Card>
                </div>
                <div className="p-field p-m-0 p-py-1 p-px-1 p-col-12 p-md-6 p-lg-4">
                    <Card>
                        <div>
                            <h3>Ordens por técnico</h3>
                        </div>
                        <div style={{width:100+'%', height: 95+'%'}}>
                            <HorizontalBar labels={grafTecnico.label} data={grafTecnico.data}/>
                        </div>
                    </Card>
                </div>
            </div>
        </Cards>

        <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-m-0 p-py-1 p-px-1 p-col-12">
                    <Card minWidth>
                        <div>
                            <h3>Ordens em aberto</h3>
                        </div>
                        <Table data={chamados} header>
                            <Column field={'id'}        header={'ID'}               body={idTemplate}       style={{width:'3.5rem'}} sortable filter={false} width="70px" />
                            <Column field={'titulo_ocorrencia'}      header={'TÍTULO'}             body={tituloOcorrenciaTemplate}     sortable filter={false}></Column>
                            <Column field={'cliente_os.fantasia'}     header={'CLIENTE'}           body={clienteFantasiaTemplate}    sortable filter={false}></Column>
                            <Column field={'equipamento_os.nome'}     header={'EQUIPAMENTO'}           body={equipamentoChamadoNomeTemplate}    sortable filter={false}></Column>
                            <Column field={'tipo_manutencao'}     header={'TIPO MANUTENÇÃO'}           body={tipoManutencaoTemplate}    sortable filter={false}></Column>
                            <Column field={'createdAt'} header={'DATA CRIAÇÃO'}     body={createdTemplate}  sortable filter={false}></Column>
                            <Column field={'status'}    header={'STATUS'}           body={statusChamadoTemplate}   sortable filter={false}></Column>
                            <Column field={'tecnico_os.name'}     header={'TÉCNICO'}           body={tecnicoChamadoNomeTemplate}    sortable filter={false}></Column>
                            <Column field={'inicio_atendimento'} header={'INICIO ATENDIMENTO'} body={inicioAtendimentoTemplate}  sortable filter={false}></Column>
                            {/* <Column field={'fim_atendimento'} header={'FIM ATENDIMENTO'} body={fimAtendimentoTemplate}  sortable filter={false}></Column> */}
                            {/* <Column body={actionBodyTemplate} style={{width:'7%'}} ></Column> */}
                        </Table>
                    </Card>
                </div>
        </div>
    </Layout>
}