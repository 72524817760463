import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from '../../../services/api'

import Loading from '../../../components/loading'
import Title from '../../../components/title'
import Card from '../../../components/card'

import InputText from '../../../components/form/primeReact/inputText'
import InputMask from '../../../components/form/primeReact/inputMask'
import SelectPrime from '../../../components/form/primeReact/select'
import ButtonPrime from '../../../components/form/primeReact/button'


import { Container } from './styles'

const NovoUser : React.FC = ({ idCliente, idEquipamento }) =>{
    const [loading, setLoading] = useState(false)
    
    const [toggleResp, setToggleResp] = useState(false)
    const [responsaveis, setResponsaveis] = useState([])
    const [respTecnico, setRespTecnico]= useState('')

    const [id, setId] = useState('')
    const [cliente, setCliente] = useState('')
    const [nome, setNome] = useState('')
    const [fabricante, setFabricante] = useState('');
    const [modelo, setModelo] = useState('')
    const [serie, setSerie] = useState('')
    const [localFisico, setLocalFisico]= useState('')
    const [status, setStatus]= useState('')

    const [responsavel, setResponsavel]= useState('')
    const [telResponsavel, setTelResponsavel]= useState('')
    const [emailResponsavel, setEmailResponsavel]= useState('')

    // const  idCliente = idCliente;
    // const  idEquipamento = idEquipamento;

    const [edit, setEdit] = useState(true)

    const {perfil : perfilLogged} = JSON.parse(localStorage.getItem('user') || '');    

    const dataStatus = [{value: 'Ativo', label: 'Ativo'},{value: 'Inativo', label: 'Inativo'}]

    useEffect(() => {
        async function handle_getEquipamento() {
            setLoading(true);
            setCliente(idCliente);

            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${idCliente}/equipamento/${idEquipamento}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setId(response.data?.result?.id)
                    setNome(response.data?.result?.nome?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setFabricante(response.data?.result?.fabricante.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setSerie(response.data?.result?.serie?.toUpperCase())
                    setModelo(response.data?.result?.modelo?.toUpperCase())
                    setLocalFisico(response.data?.result?.local_fisico?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setResponsavel(response.data?.result?.responsavel_tecnico?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setTelResponsavel(response.data?.result?.telefone_tecnico)
                    setEmailResponsavel(response.data?.result?.email_tecnico?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    }))
                    setStatus(response.data?.result?.status)
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                });
            setLoading(false)
        }
        async function handle_getClientes(){
            setLoading(true)

            const token = sessionStorage.getItem('token');
                await api.get(`/clientes/${idCliente}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setResponsaveis(response?.data?.result?.contatos_cliente);
                    // setResponsaveis(response?.data?.result?.contatos_cliente?.map(responsavel => {
                    //     return {'value': responsavel.id, 'label': responsavel.contato?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                    //         return a.toUpperCase();
                    //     }) + ' - ' + responsavel.cargo}
                    // }));
                })
                .catch(error => {
                    if ((error.response?.data.error)){
                        toast.error(error.response?.data.error)
                      }else{
                        toast.error(error.message)
                      }
                }).finally(() => setLoading(false));
        }

        handle_getClientes();
        handle_getEquipamento();
    }, [idCliente, idEquipamento])

    function handleEditarEquipamento(event){
        event.preventDefault()
        setLoading(true)

        const token = sessionStorage.getItem('token');
        try {
            api.put(`/clientes/${idCliente}/equipamento/${idEquipamento}`,
            {
                nome,
                fabricante,
                modelo,
                serie,
                responsavel_tecnico: responsavel,
                telefone_tecnico: telResponsavel,
                email_tecnico: emailResponsavel,
                local_fisico: localFisico,
                status
            },{
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLoading(false)
                if (response.data.original){
                    toast.error(response.data.original.message);
                }
                if (response.data.error){
                    toast.error(response.data.error);
                }
    
                if (response.data.code === 201)
                    toast.success('Equipamento editado com sucesso');

            }).catch(error => {
                setLoading(false)
                if ((error.response?.data.error)){
                  toast.error(error.response?.data.error)
                }else{
                  toast.error(error.message)
                }
              });
            // setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
    }    

    useEffect(()=>{
        function handle_getResponsaveis(){
            console.log(responsaveis)
            const resp = responsaveis?.filter((respo) => respo.uuid === respTecnico)

            setResponsavel(resp[0]?.contato)
            setTelResponsavel(resp[0]?.telefone1?.replace(/\D/g, ""))
            setEmailResponsavel(resp[0]?.email)

            // console.log(responsaveis, resp)
        }

        handle_getResponsaveis()
    }, [respTecnico])

    function handleLiberarEdicao(event){
        event.preventDefault()
        setEdit(false)
    }
    //   function handleEditarSenhaTexto(){
    //     setEditSenhaTexto(editSenhaTexto === 'text' ? 'password' : 'text')
    //   }

    return <>
        <Loading loading={loading} message='Carregando...' />

        <Title title={"Editar Cadastro de Equipamento"}>
            {/* <NavLink to="/users/novo" className="btn btn-primary mb-2"><MdPersonAdd fontSize="24px" /> Novo Usuário</NavLink> */}
        </Title>

        <Card width="100%">
            <Container>                
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-sm-2">
                        <InputText
                            title={'ID'} 
                            name="id" 
                            value={id}
                            disabled={true}
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-7">
                        <InputText
                            title={'Nome Equipamento'} 
                            name="nomeEquipamento" 
                            value={nome}
                            setData={event => setNome(event.target.value)}
                            focus={true}
                            disabled={perfilLogged === "Operador" ? true : edit}
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-3">
                        <SelectPrime 
                            title={'Status'} 
                            name={'status'} 
                            data={dataStatus}
                            value={status}
                            setData={event => setStatus(event.value)}
                            disabled={perfilLogged === "Operador" ? true : edit}
                        />
                    </div> 
                </div> 

            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Fabricante'} 
                        name="fabricante" 
                        value={fabricante}
                        setData={event => setFabricante(event.target.value)}
                        disabled={perfilLogged === "Operador" ? true : edit}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Modelo'} 
                        name="modelo" 
                        value={modelo}
                        setData={event => setModelo(event.target.value)}
                        disabled={perfilLogged === "Operador" ? true : edit}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Série'} 
                        name="serie" 
                        value={serie}
                        setData={event => setSerie(event.target.value)}
                        disabled={perfilLogged === "Operador" ? true : edit}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-6">
                    <InputText
                        title={'Local Físico'} 
                        name="localFisico" 
                        value={localFisico}
                        setData={event => setLocalFisico(event.target.value)}
                        disabled={perfilLogged === "Operador" ? true : edit}
                    />
                </div>
                <div className="p-field p-col-12 p-sm-4">
                    {toggleResp? 
                        <SelectPrime 
                        title={'Responsável Técnico'} 
                        name="responsavelTecnico"
                        data={responsaveis?.map(responsavel => {
                            return {'value': responsavel.uuid, 'label': responsavel.contato?.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                                return a.toUpperCase();
                            }) + ' - ' + responsavel.cargo}
                        })}
                        value={respTecnico}
                        setData={event => setRespTecnico(event.value)}
                        disabled={perfilLogged === "Operador" ? true : edit}
                        />
                    : 
                        <InputText
                            title={'Responsável Técnico'} 
                            name="responsavelTecnico" 
                            value={responsavel}
                            setData={event => setResponsavel(event.target.value)}
                            disabled={perfilLogged === "Operador" ? true : edit}
                            buttonFunction={(event) => setToggleResp(current => !current)}
                            buttonIcon={'user-edit'}
                        />
                    }
                </div>
                <div className="p-field p-col-12 p-sm-4">
                    <InputMask
                        title={"Telefone Responsável"}
                        name={"telefoneResponsavel"}
                        value={telResponsavel}
                        setData={event => setTelResponsavel(event.target.value)}
                        mask={"(99) 9 9999-999?9"}
                        disabled={perfilLogged === "Operador" ? true : edit}
                        />  
                </div>      
                <div className="p-field p-col-12 p-sm-4">
                    <InputText
                        title={'E-mail Técnico'} 
                        name="emailTecnico" 
                        value={emailResponsavel}
                        setData={event => setEmailResponsavel(event.target.value)}
                        disabled={perfilLogged === "Operador" ? true : edit}
                        />
                </div>            
            </div>
              
            {
                perfilLogged === "Operador" ? '' :
                !edit ?
                    // <button className="btn btn-primary mt-2" onClick={handleEditarUsuario}><MdSave fontSize={25}/> Salvar</button>
                    <ButtonPrime onClick={handleEditarEquipamento} text="Salvar" icon="save"/>
                :
                    // <button className="btn btn-outline-success mt-2" onClick={handleLiberarEdicao}><MdEdit fontSize={25}/> Editar</button>
                    <ButtonPrime onClick={handleLiberarEdicao} text="Editar" icon="lock-open"/>
            }
            
            </Container>
        </Card>
            </>
}

export default NovoUser;